import styled from "styled-components";
import { tema_hotsite } from "../../../../assets/tema";

export const RodapeContainer = styled.section`
    width: 100%;
    padding: 10px;
    background-color: ${tema_hotsite.cor};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
`;

export const LogoBrancaRodape = styled.img`
    width: 200px;
    object-fit: contain;
`;

export const RodapeNavContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;

    @media screen and (max-width: 768px){
     flex-direction: column;
     align-items: center;
     text-align: center;
     gap: 15px;
    }
`;

export const RodapeNavWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const RodapeNavLink = styled.a`
    font-size: 17px;
    color: white;
    text-decoration: none;
    cursor: pointer;

    &:hover{
        text-decoration: underline;
    }
`;