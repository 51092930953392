import styled from "styled-components";
import { tema_hotsite } from "../../../../assets/tema";

export const ContainerFiltros = styled.div`
width: 100%;
padding: 20px;
gap: 10px 0px;
flex-wrap: wrap;
flex-direction: row;
display: flex;
justify-content: space-between;
`;
export const BoxFiltro = styled.div`
width: 300px;
align-items: end;
display: block;
height: auto;
`;
export const LabelFiltro = styled.label`
display: block;
`;

export const SelectFiltro = styled.select`
margin: 10px 10px 10px 0px;
width: 100%;
padding:6px;
`;

export const InputFiltro = styled.input`
margin: 10px 10px 0px 0px;
padding:5px;
width: 200px;
`;

export const Wrapper = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
`;