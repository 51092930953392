import { Component } from "react";
import { AcoesContainer, AcoesContainerBotao, BoxHeaderTable, CabecalhoPrincipal, CabecalhoPrincipalBotao, CabecalhoPrincipalBotaoContainer, CabecalhoTopo, CabecalhoTopoBotao, CabecalhoTopoDireito, CabecalhoTopoEsquerdo, ContainerPrincipal, ConteudoPrincipal, ConteudoPrincipalContainer, LogoPrincipal, Tabela, TabelaCabeca, TabelaCabecaLinha, TabelaCabecaTitulo, TabelaContainer, TabelaCorpo, TabelaCorpoInformacao, TabelaCorpoLinha } from "../../../components/standard";
import { GoEye } from 'react-icons/go';
import { GrUpdate, GrSearch } from 'react-icons/gr';
import { Navigate } from "react-router-dom";

import Constante from '../../../Util/Constante';
import Util from '../../../Util/Util';
import DepartamentoService from 'lirida-back-service/Servicos/Departamento/DepartamentoService';
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import DocumentoService from 'lirida-back-service/Servicos/Documento/DocumentoService';
import FinanceiroService from "lirida-back-service/Servicos/Financeiro/FinanceiroService";

import logo from '../../../assets/images/logo.svg'
import { BoxFiltro, ContainerFiltros, InputFiltro, LabelFiltro, SelectFiltro, Wrapper } from "./Components/standardFinanceiro";


import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { FaFileExcel } from "react-icons/fa";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";


export default class FinanceiroPG extends Component {

    constante = new Constante();
    util = new Util();
    departamentoService = new DepartamentoService(this.constante.token, this.constante.url_base);
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);
    financeiroService = new FinanceiroService(this.constante.token, this.constante.url_base);

    state = {
        // LISTAS
        listaMovimentoFinanceiro: [],
        listaContaFinanceira: [],
        listaFormaPagamento: [],
        listaContaBancaria: [],

        detalheVisivel: false,
        liberarAcessoTelaLogin: false,
        liberarAcessoTelaDocumento: false,
        pessoaUsuario: new Pessoa(),



        /* Filtros */
        seqContaBancaria: '',
        seqFormaPagamento: '',
        seqContaFinanceira: '',
        status: '',
        dataCadastro_inicial: '',
        dataCadastro_final: '',
        dataProgramada_inicial: '',
        dataProgramada_final: '',
        dataBaixa_inicial: '',
        dataBaixa_final: '',
        dataCredito_inicial: '',
        dataCredito_final: '',


        //sorting
        sorting: 'crescente'
    }


    componentDidMount() {
        this.iniciar();

    }


    async iniciar() {
        this.retornoLocalStorage = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        this.pessoaUsuario = this.retornoLocalStorage;
        if (!this?.pessoaUsuario?._seqPessoa) {
            window.history.back()
            return
        }

        this.retornoListaContaFinanceira = await this.util.listarLista(this.constante.seqUsuario, this.constante.idListaContaFinanceira);
        this.retornoListaFormaPagamento = await this.util.listarLista(this.constante.seqUsuario, this.constante.idListaFormaPagamento);
        this.retornoListaContaBancaria = await this.util.listarLista(this.constante.seqUsuario, this.constante.idListaContaBancaria);


        this.listaContaFinanceira = this.retornoListaContaFinanceira.objeto;
        this.listaFormaPagamento = this.retornoListaFormaPagamento.objeto;
        this.listaContaBancaria = this.retornoListaContaBancaria.objeto;


        this.listarFinanceiro();

        this.setState({
            listaContaFinanceira: this.listaContaFinanceira,
            listaFormaPagamento: this.listaFormaPagamento,
            listaContaBancaria: this.listaContaBancaria,
            pessoaUsuario: this.pessoaUsuario,
        });


    }

    async listarFinanceiro() {
        this.lista = [
            this.util.criarObjetoPesquisa(
                "movimento_financeiro.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            )
        ]

        if (this.state.seqContaBancaria) {
            this.lista.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro.seq_conta_bancaria",
                    this.state.seqContaBancaria,
                    "AND",
                    "TEXTO"
                )
            )
        }

        if (this.state.seqFormaPagamento) {
            this.lista.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro.seq_forma_pagamento",
                    this.state.seqFormaPagamento,
                    "AND",
                    "TEXTO"
                )
            )
        }


        if (this.state.seqContaFinanceira) {
            this.lista.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro.seq_conta_financeira",
                    this.state.seqContaFinanceira,
                    "AND",
                    "TEXTO"
                )
            )
        }

        if (this.state.status) {
            if (this.state.status === "0") {
                this.lista.push(
                    this.util.criarObjetoPesquisa(
                        "movimento_financeiro.qtde_baixa",
                        this.state.status,
                        "AND",
                        "NUMERO"
                    )
                );
            } else {
                this.lista.push(
                    this.util.criarObjetoPesquisa(
                        "AND movimento_financeiro.qtde_baixa > 0",
                        this.state.status,
                        "AND",
                        "MANUAL"
                    )
                );
            }
        }

        if (this.state.dataCadastro_inicial) {
            this.lista.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro.data_cadastro",
                    this.util.formatarDataInput(this.state.dataCadastro_inicial),
                    "AND",
                    "DATA_INICIAL"
                )
            );
        }


        if (this.state.dataCadastro_final) {
            this.lista.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro.data_cadastro",
                    this.util.formatarDataInput(this.state.dataCadastro_final),
                    "AND",
                    "DATA_FINAL"
                )
            );
        }


        if (this.state.dataProgramada_inicial) {
            this.lista.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro.data_programado",
                    this.util.formatarDataInput(this.state.dataProgramada_inicial),
                    "AND",
                    "DATA_INICIAL"
                )
            );
        }

        if (this.state.dataProgramada_final) {
            this.lista.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro.data_programado",
                    this.util.formatarDataInput(this.state.dataProgramada_final),
                    "AND",
                    "DATA_FINAL"
                )
            );
        }


        if (this.state.dataBaixa_inicial) {
            this.lista.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro_baixa.data_baixa",
                    this.util.formatarDataInput(this.state.dataBaixa_inicial),
                    "AND",
                    "DATA_INICIAL"
                )
            );
        }


        if (this.state.dataBaixa_final) {
            this.lista.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro_baixa.data_baixa",
                    this.util.formatarDataInput(this.state.dataBaixa_final),
                    "AND",
                    "DATA_FINAL"
                )
            );
        }


        if (this.state.dataCredito_inicial) {
            this.lista.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro_baixa.data_credito",
                    this.util.formatarDataInput(this.state.dataCredito_inicial),
                    "AND",
                    "DATA_INICIAL"
                )
            );
        }


        if (this.state.dataCredito_final) {
            this.lista.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro_baixa.data_credito",
                    this.util.formatarDataInput(this.state.dataCredito_final),
                    "AND",
                    "DATA_FINAL"
                )
            );
        }


        console.log(this.lista);
        this.retorno = await this.financeiroService.listarComFiltro(this.lista);
        console.log("================>")
        console.log(this.retorno.objeto)

        for (let i = 0; i < this.retorno.objeto.length; i++) {
            if (!this.retorno.objeto[i].valorTaxa) {
                this.retorno.objeto[i].valorTaxa = 0;
            }
            if (!this.retorno.objeto[i].valorLiquido) {
                this.retorno.objeto[i].valorLiquido = 0;
            }
        }
        console.log('============>')
        console.log(this.retorno.objeto)
        this.setState({
            listaMovimentoFinanceiro: this.retorno.objeto,
            seqContaBancaria: '',
            seqFormaPagamento: '',
            seqContaFinanceira: '',
            status: '',
            dataCadastro_inicial: '',
            dataCadastro_final: '',
            dataProgramada_inicial: '',
            dataProgramada_final: '',
            dataBaixa_inicial: '',
            dataBaixa_final: '',
            dataCredito_inicial: '',
            dataCredito_final: '',
        });
    }

    sortingLetra(col) {
        if (this.state.sorting === 'crescente') {
            this.sort = [...this.state.listaMovimentoFinanceiro].sort((a, b) => a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1);
            this.setState({ listaMovimentoFinanceiro: this.sort, sorting: 'decrescente' })
        }

        if (this.state.sorting === 'decrescente') {
            this.sort = [...this.state.listaMovimentoFinanceiro].sort((a, b) => a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1);
            this.setState({ listaMovimentoFinanceiro: this.sort, sorting: 'crescente' })
        }
    }

    sortingNumero(col) {
        if (this.state.sorting === 'crescente') {
            this.sort = [...this.state.listaMovimentoFinanceiro].sort((a, b) => a[col] - b[col]);
            this.setState({ listaMovimentoFinanceiro: this.sort, sorting: 'decrescente' })
        }

        if (this.state.sorting === 'decrescente') {
            this.sort = [...this.state.listaMovimentoFinanceiro].sort((a, b) => b[col] - a[col]);
            this.setState({ listaMovimentoFinanceiro: this.sort, sorting: 'crescente' })
        }
    }
    retornaTipoData(pStringData) {
        this.dataAtual = pStringData;
        this.dataAtual = this.dataAtual.split('T')[0];
        this.dataAtual = this.dataAtual.split('-').reverse();
        this.ano = this.dataAtual[0];
        this.mes = this.dataAtual[1];
        this.dia = this.dataAtual[2];
        this.novaData = `${this.ano}/${this.mes}/${this.dia}`;
        this.novaData = new Date(this.novaData);
        console.log(this.novaData);
        return this.novaData
    }

    sortingData(col) {
        if (this.state.sorting === 'crescente') {
            this.sort = [...this.state.listaMovimentoFinanceiro].sort((a, b) => this.retornaTipoData(a[col]) - this.retornaTipoData(b[col]));
            this.setState({ listaMovimentoFinanceiro: this.sort, sorting: 'decrescente' })
        }

        if (this.state.sorting === 'decrescente') {
            this.sort = [...this.state.listaMovimentoFinanceiro].sort((a, b) => this.retornaTipoData(b[col]) - this.retornaTipoData(a[col]));
            this.setState({ listaMovimentoFinanceiro: this.sort, sorting: 'crescente' })
        }
    }


    sair() {
        this.util.removerLocalStorage("PESSOA_USUARIO");
        this.setState({ liberarAcessoTelaLogin: true });
    }

    render() {
        if (!this.state.pessoaUsuario._seqPessoa) {
            return <div></div>
        }
        return <ContainerPrincipal>

            {this.state.liberarAcessoTelaLogin && <Navigate to="/login" />}
            {this.state.liberarAcessoTelaDocumento && <Navigate to="/documento" />}

            <CabecalhoTopo>
                <CabecalhoTopoEsquerdo>Bem vindo, {this.state.pessoaUsuario.nome}</CabecalhoTopoEsquerdo>
                <CabecalhoTopoDireito>
                    {this.state.pessoaUsuario.emailPlataformaLirida}
                    <CabecalhoTopoBotao onClick={() => this.sair()}>sair</CabecalhoTopoBotao>
                </CabecalhoTopoDireito>
            </CabecalhoTopo>
            <CabecalhoPrincipal>
                <LogoPrincipal src={logo} />
                <CabecalhoPrincipalBotaoContainer>
                    <CabecalhoPrincipalBotao onClick={() => this.setState({ liberarAcessoTelaDocumento: true })}>Ordens de serviço</CabecalhoPrincipalBotao>
                    <CabecalhoPrincipalBotao selecionado={true} >Financeiro</CabecalhoPrincipalBotao>
                </CabecalhoPrincipalBotaoContainer>
            </CabecalhoPrincipal>

            <ConteudoPrincipal>
                <AcoesContainer>
                    <AcoesContainerBotao onClick={() => this.listarFinanceiro()}>
                        <GrUpdate size={25} />
                        <div>Atualizar</div>
                    </AcoesContainerBotao>

                    <AcoesContainerBotao onClick={() => this.listarFinanceiro()}>
                        <GrSearch size={25} />
                        <div>Buscar</div>
                    </AcoesContainerBotao>

                </AcoesContainer>
                <ContainerFiltros>
                    <BoxFiltro>
                        <LabelFiltro>Conta bancária</LabelFiltro>
                        <SelectFiltro
                            onChange={pTexto => this.setState({ seqContaBancaria: pTexto.target.value })}
                            value={this.state.seqContaBancaria}
                        >
                            <option value={''}>Selecione</option>
                            {this.state.listaContaBancaria.map(pContaBancaria =>
                                <option value={pContaBancaria._seq}>{pContaBancaria._nome}</option>
                            )}
                        </SelectFiltro>
                    </BoxFiltro>
                    <BoxFiltro>
                        <LabelFiltro>Forma de Pagamento</LabelFiltro>
                        <SelectFiltro
                            onChange={pTexto => this.setState({ seqFormaPagamento: pTexto.target.value })}
                            value={this.state.seqFormaPagamento}
                        >
                            <option value={''}>Selecione</option>
                            {this.state.listaFormaPagamento.map(pFormaPagamento =>
                                <option value={pFormaPagamento._seq}>{pFormaPagamento._nome}</option>
                            )}
                        </SelectFiltro>
                    </BoxFiltro>
                    <BoxFiltro>
                        <LabelFiltro>Conta Financeira</LabelFiltro>
                        <SelectFiltro
                            onChange={pTexto => this.setState({ seqContaFinanceira: pTexto.target.value })}
                            value={this.state.seqContaFinanceira}
                        >
                            <option value={''}>Selecione</option>
                            {this.state.listaContaFinanceira.map(pContaFinanceira =>
                                <option value={pContaFinanceira._seq}>{pContaFinanceira._nome}</option>
                            )}
                        </SelectFiltro>
                    </BoxFiltro>
                    <BoxFiltro>
                        <LabelFiltro>Status</LabelFiltro>
                        <SelectFiltro
                            onChange={pTexto => this.setState({ status: pTexto.target.value })}
                            value={this.state.status}
                        >
                            <option value={''}>Selecione</option>
                            <option value={'0'}>Não pago</option>
                            <option value={'1'}>Pago</option>
                        </SelectFiltro>
                    </BoxFiltro>

                    <BoxFiltro>
                        <LabelFiltro>Data cadastro</LabelFiltro>
                        <Wrapper>
                            <InputFiltro
                                onChange={pTexto => this.setState({ dataCadastro_inicial: pTexto.target.value })}
                                value={this.state.dataCadastro_inicial}
                                type="date"
                            />
                            <InputFiltro
                                onChange={pTexto => this.setState({ dataCadastro_final: pTexto.target.value })}
                                value={this.state.dataCadastro_final}
                                type="date"
                            />
                        </Wrapper>
                    </BoxFiltro>

                    <BoxFiltro>
                        <LabelFiltro>Data programada</LabelFiltro>
                        <Wrapper>
                            <InputFiltro
                                onChange={pTexto => this.setState({ dataProgramada_inicial: pTexto.target.value })}
                                value={this.state.dataProgramada_inicial}
                                type="date"
                            />

                            <InputFiltro
                                onChange={pTexto => this.setState({ dataProgramada_final: pTexto.target.value })}
                                value={this.state.dataProgramada_final}
                                type="date"
                            />
                        </Wrapper>
                    </BoxFiltro>

                    <BoxFiltro>
                        <LabelFiltro>Data baixa</LabelFiltro>
                        <Wrapper>
                            <InputFiltro
                                onChange={pTexto => this.setState({ dataBaixa_inicial: pTexto.target.value })}
                                value={this.state.dataBaixa_inicial}
                                type="date"
                            />
                            <InputFiltro
                                onChange={pTexto => this.setState({ dataBaixa_final: pTexto.target.value })}
                                value={this.state.dataBaixa_final}
                                type="date"
                            />
                        </Wrapper>
                    </BoxFiltro>

                    <BoxFiltro>
                        <LabelFiltro>Data crédito</LabelFiltro>
                        <Wrapper>
                            <InputFiltro
                                onChange={pTexto => this.setState({ dataCredito_inicial: pTexto.target.value })}
                                value={this.state.dataCredito_inicial}
                                type="date"
                            />
                            <InputFiltro
                                onChange={pTexto => this.setState({ dataCredito_final: pTexto.target.value })}
                                value={this.state.dataCredito_final}
                                type="date"
                            />
                        </Wrapper>
                    </BoxFiltro>
                </ContainerFiltros>


                <Wrapper style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}>
                    <ReactHtmlTableToExcel
                        table="financeiro"
                        filename="Finanças"
                        sheet="Pagina 1"
                        buttonText={<FaFileExcel size={35} color='#388a3a' />}
                    />
                </Wrapper>

                <ConteudoPrincipalContainer>

                    <TabelaContainer style={{ width: '100%' }}>
                        <Tabela id="financeiro">
                            <TabelaCabeca>
                                <TabelaCabecaLinha>
                                    <TabelaCabecaTitulo>ID</TabelaCabecaTitulo>

                                    <TabelaCabecaTitulo onClick={() => this.sortingLetra("nome")}>
                                        <BoxHeaderTable>
                                            Nome
                                            {this.state.sorting === 'crescente' ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
                                        </ BoxHeaderTable>
                                    </TabelaCabecaTitulo>

                                    <TabelaCabecaTitulo onClick={() => this.sortingLetra("_fpNome")}>
                                        <BoxHeaderTable>
                                            Forma pagamento
                                            {this.state.sorting === 'crescente' ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
                                        </ BoxHeaderTable>
                                    </TabelaCabecaTitulo>

                                    <TabelaCabecaTitulo onClick={() => this.sortingLetra("_cfOperacao")}>
                                        <BoxHeaderTable>
                                            Operação
                                            {this.state.sorting === 'crescente' ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
                                        </ BoxHeaderTable>
                                    </TabelaCabecaTitulo>

                                    <TabelaCabecaTitulo onClick={() => this.sortingNumero("valorProgramado")}>
                                        <BoxHeaderTable>
                                            Valor
                                            {this.state.sorting === 'crescente' ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
                                        </ BoxHeaderTable>
                                    </TabelaCabecaTitulo>

                                    <TabelaCabecaTitulo onClick={() => this.sortingNumero("valorTaxa")}>
                                        <BoxHeaderTable>
                                            Valor Taxa
                                            {this.state.sorting === 'crescente' ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
                                        </ BoxHeaderTable>
                                    </TabelaCabecaTitulo>

                                    <TabelaCabecaTitulo onClick={() => this.sortingNumero("valorLiquido")}>
                                        <BoxHeaderTable>
                                            Valor Líquido
                                            {this.state.sorting === 'crescente' ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
                                        </ BoxHeaderTable>
                                    </TabelaCabecaTitulo>

                                    <TabelaCabecaTitulo onClick={() => this.sortingNumero("qtdeBaixa")}>
                                        <BoxHeaderTable>
                                            Status
                                            {this.state.sorting === 'crescente' ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
                                        </ BoxHeaderTable>
                                    </TabelaCabecaTitulo>

                                    <TabelaCabecaTitulo onClick={() => this.sortingData("dataProgramado")}>
                                        <BoxHeaderTable>
                                            Data programada
                                            {this.state.sorting === 'crescente' ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
                                        </ BoxHeaderTable>

                                    </TabelaCabecaTitulo>

                                    <TabelaCabecaTitulo onClick={() => this.sortingNumero("qtdeBaixa")}>
                                        <BoxHeaderTable>
                                            Data crédito
                                            {this.state.sorting === 'crescente' ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
                                        </ BoxHeaderTable>
                                    </TabelaCabecaTitulo>

                                    <TabelaCabecaTitulo onClick={() => this.sortingData("dataCadastro")}>
                                        <BoxHeaderTable>
                                            Data cadastro
                                            {this.state.sorting === 'crescente' ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
                                        </ BoxHeaderTable>
                                    </TabelaCabecaTitulo>

                                    <TabelaCabecaTitulo>
                                        Ação
                                    </TabelaCabecaTitulo>

                                </TabelaCabecaLinha>
                            </TabelaCabeca>
                            <TabelaCorpo>
                                {this.state.listaMovimentoFinanceiro.map(pFinanceiro =>
                                    <TabelaCorpoLinha>
                                        <TabelaCorpoInformacao>{pFinanceiro._seqMovimentoFinanceiro}</TabelaCorpoInformacao>
                                        <TabelaCorpoInformacao>{pFinanceiro._pesNome}</TabelaCorpoInformacao>
                                        <TabelaCorpoInformacao>{pFinanceiro._fpNome}</TabelaCorpoInformacao>
                                        <TabelaCorpoInformacao>{pFinanceiro._cfOperacao}</TabelaCorpoInformacao>
                                        <TabelaCorpoInformacao valor={true}>{this.util.formatarValor(pFinanceiro.valorProgramado)}</TabelaCorpoInformacao>
                                        <TabelaCorpoInformacao valor={true}>{this.util.formatarValor(pFinanceiro.valorTaxa)}</TabelaCorpoInformacao>
                                        <TabelaCorpoInformacao valor={true}>{this.util.formatarValor(pFinanceiro.valorLiquido)}</TabelaCorpoInformacao>
                                        <TabelaCorpoInformacao>
                                            {pFinanceiro.qtdeBaixa === 0 && 'Não pago'}
                                            {pFinanceiro.qtdeBaixa === 1 && 'Pago'}
                                        </TabelaCorpoInformacao>
                                        <TabelaCorpoInformacao>{this.util.formatarData(pFinanceiro.dataProgramado)}</TabelaCorpoInformacao>
                                        <TabelaCorpoInformacao>{this.util.formatarData(pFinanceiro._dataCredito)}</TabelaCorpoInformacao>
                                        <TabelaCorpoInformacao>{this.util.formatarData(pFinanceiro.dataCadastro)}</TabelaCorpoInformacao>
                                        <TabelaCorpoInformacao>
                                            <Wrapper>
                                                <button onClick={() => {
                                                    window.open(this.constante.url_base_crosspag + pFinanceiro._seqMovimentoFinanceiro, '_blank')
                                                }}>
                                                    <GoEye size={20} />
                                                </button>
                                            </Wrapper>
                                        </TabelaCorpoInformacao>
                                    </TabelaCorpoLinha>
                                )}
                            </TabelaCorpo>
                        </Tabela>
                    </TabelaContainer>
                </ConteudoPrincipalContainer>
            </ConteudoPrincipal>
        </ContainerPrincipal >
    }
}

