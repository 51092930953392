import { Component } from "react";
import { HeaderContainer, HeaderLogo, Navbar, NavbarLink } from "./style";
// import owLogo from '../../../../assets/imagens/logo-ow.png';
import logo from '../../../../assets/images/logo.svg';
export default class extends Component {

    render() {
        return <HeaderContainer id='header'>
            <HeaderLogo src={logo} alt="Faxinex"
                onClick={() => this.props.setState({ telaTermoDeUso: false, telaPoliticaPrivacidade: false })} />
            <Navbar>
                <NavbarLink
                    href="#sobre"
                    onClick={() => this.props.setState({ telaTermoDeUso: false, telaPoliticaPrivacidade: false })}>
                    Sobre
                </NavbarLink>
                <NavbarLink
                    href="#funcionalidade"
                    onClick={() => this.props.setState({ telaTermoDeUso: false, telaPoliticaPrivacidade: false })}>
                    Funcionalidade
                </NavbarLink>
                <NavbarLink
                    href="#depoimentos"
                    onClick={() => this.props.setState({ telaTermoDeUso: false, telaPoliticaPrivacidade: false })}>
                    Depoimentos
                </NavbarLink>
                <NavbarLink href="#" onClick={(event) => { event.preventDefault(); this.props.emailConsulta() }}>Consulte</NavbarLink>
            </Navbar>
        </HeaderContainer>
    }
}