import Pesquisa from 'lirida-back-service/Servicos/Pesquisa/Pesquisa';
import Util_api from 'lirida-back-service/Servicos/Util/Util';
import Constante from './Constante';

export default class Util {
    constante = new Constante();
    util_api = new Util_api(this.constante.token, this.constante.url_base);



    ////// *** BUSCAS *** /////


    async buscarUrlBaseArquivo() {
        let retorno = await this.util_api.buscarUrlBaseArquivo();
        retorno = await retorno.json();
        return retorno;
    }


    async buscarCep(pCep) {
        let retorno = await fetch('https://viacep.com.br/ws/' + pCep + '/json')
        retorno = await retorno.json();

        return retorno;
    }
    ///////////*****************/////////////




    ////// *** STORAGE *** /////

    salvarLocalStorage(pCampo, pPessoa) {
        window.localStorage.setItem(pCampo, JSON.stringify(pPessoa));
    }

    async buscarLocalStorage(pCampo, pPessoa) {
        this.retorno = await window.localStorage.getItem(pCampo, pPessoa);
        this.retorno = await JSON.parse(this.retorno);
        return this.retorno
    }

    removerLocalStorage(pCampo) {
        window.localStorage.removeItem(pCampo)
    }

    ///////////*****************/////////////




    ////// *** LISTA / LISTAR *** /////

    async listarBanco(pConteudo) {
        this.listaBanco = await this.util_api.listarBanco(pConteudo);
        this.listaBanco = await this.listaBanco.json();
        return this.listaBanco;
    }

    async listarLista(pSeqUsuario, pID) {
        this.retorno = await this.util_api.listarLista(pSeqUsuario, pID);
        this.retorno = this.retorno.json();
        return this.retorno;
    }

    ///////////*****************/////////////


    ////// *** OBJETOS *** /////
    criarObjetoPesquisa(pCampo, pConteudo, pOperacao, pTipo) {
        this.pesquisa = new Pesquisa()

        this.pesquisa.campo = pCampo;
        this.pesquisa.conteudo = pConteudo;
        this.pesquisa.operacao = pOperacao;
        this.pesquisa.tipo = pTipo;

        return this.pesquisa
    }
    ///////////*****************/////////////

    ////// *** ENVIAR EMAILS *** ///// 


    async enviarEmailConfirmarPedido(pEmailPessoa, pNomePessoa, seqMovimentoFinanceiro) {
        console.log(pEmailPessoa, pNomePessoa);
        await this.util_api.enviarEmail({
            seqEmail: this.constante.seqEmailEnvioConfirmacaoPedido,
            email: pEmailPessoa,
            parametro: {
                nome: pNomePessoa,
                linkPagamento: seqMovimentoFinanceiro
            }
        })
        return this.retorno
    }


    async enviarEmailSenha(pSeqEmail, pEmail, pNome, pSenha) {
        console.log(pSeqEmail, pEmail, pNome, pSenha);
        await this.util_api.enviarEmail({
            seqEmail: pSeqEmail,
            email: pEmail,
            parametro: {
                nome: pNome,
                email: pEmail,
                senha: pSenha
            }
        })
        return this.retorno
    }
    ///////////*****************/////////////



    ////// *** FORMATACAO *** /////

    formatarDataInput(pData) {
        if (String(pData).includes('/')) {
            this.data = String(pData).split('/').reverse().join('-');
        } else if (String(pData).includes('-')) {
            this.data = String(pData).split('-').reverse().join('/');
        } else {
            this.data = pData;
        }

        return this.data;
    }

    formatarData(pData) {
        if (!pData) {
            return null
        }
        this.data = pData;
        this.data = String(this.data).split('T')[0];
        this.data = this.data.replace('-', '/').replace('-', '/')
        return this.data;
    }

    formatarCEP(pTexto) {
        this.texto = pTexto;
        return this.texto.replace(/\D/g, '')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{3})\d+?$/, '$1')
    }


    formatarCPFCNPJ(pTexto) {
        let t = pTexto;

        if (t.length > 15) {
            return t.replace(/\D/g, '')
                .replace(/(\d{2})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})/, '$1/$2')
                .replace(/(\d{4})(\d{1,2})/, '$1-$2')
                .replace(/(-\d{2})\d+?$/, '$1')
        }

        if (t.length <= 15) {
            return t.replace(/\D/g, '')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        }
    }


    formatarTelefone(value) {
        let r = value.replace(/\D/g, '');
        r = r.replace(/^0/, '');

        if (r.length > 11) {
            r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
        } else if (r.length > 7) {
            r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
        } else if (r.length > 2) {
            r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
        } else if (value.trim() !== '') {
            r = r.replace(/^(\d*)/, '($1');
        }

        return r;
    }



    formatarValor(pValor) {
        if (!pValor) {
            return `0,00`
        }

        this.valor = Number(pValor).toFixed(2);
        this.valor = String(this.valor).replace('.', '');
        this.valor = this.valor.replace(/\D/g, ''); //permite digitar apenas números
        this.valor = this.valor.replace(/[0-9]{12}/, 'Valor inválido!'); //limita pra máximo 999.999.999,99
        this.valor = this.valor.replace(/(\d{1})(\d{8})$/, '$1.$2'); //coloca ponto antes dos últimos 8 digitos
        this.valor = this.valor.replace(/(\d{1})(\d{5})$/, '$1.$2'); //coloca ponto antes dos últimos 5 digitos
        this.valor = this.valor.replace(/(\d{1})(\d{1,2})$/, '$1,$2'); //coloca virgula antes dos últimos 2 digitos

        return ` ${this.valor}`;
    }

    ///////////*****************/////////////
}