import styled from "styled-components";
import { tema } from "../assets/tema";

export const ContainerPrincipal = styled.div`
    width: 100%;
    height: 100vh;
    /* overflow: hidden; */
`;

export const CabecalhoTopo = styled.div`
    width: 100%;
    height: 30px;
    background-color: ${tema.cor.cinza_escuro};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    color: white;
    font-size: 14px;
`;

export const CabecalhoTopoEsquerdo = styled.div``;

export const CabecalhoTopoDireito = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const CabecalhoTopoBotao = styled.button`
    width: 70px;
    height: 30px;
    background-color: transparent;
`;

export const CabecalhoPrincipal = styled.header`
    width: 100%;
    height: 60px;
    background-color: ${tema.cor.principal_escura};
    display: flex;
    justify-content: space-between;
`;

export const LogoPrincipal = styled.img`
    width: 200px;
    height: 100%;
    object-fit: contain;
    object-position: left;
    padding-left: 10px;
`;

export const CabecalhoPrincipalBotaoContainer = styled.div``;

export const CabecalhoPrincipalBotao = styled.button`
    height: 100%;
    padding: 10px;
    background-color: ${props => props.selecionado ? tema.cor.principal_saturada_escura : 'transparent'};
    color: ${props => props.selecionado ? tema.cor.principal_clara_selecionada : 'white'};
    font-size: 15px;
    border: none;
    cursor: pointer;
`;

export const ConteudoPrincipal = styled.main`
    width: 100%;
    height: calc(100% - 90px);
    background-color: ${tema.cor.principal_claro_desaturado};
`;

export const AcoesContainer = styled.div`
    width: 100%;
    height: 70px;
    padding: 10px;
`;

export const AcoesContainerBotao = styled.button`
    width: 60px;
    height: 60px;
    margin-left: 10px;
    color: ${tema.cor.cinza_escuro};
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:first-child {
        margin-left: 0;
    }
`;

export const ConteudoPrincipalContainer = styled.div`
    width: 100%;
    height: calc(100% - 90px);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`;

export const ProcessoContainer = styled.section`
    width: 220px;
    height: 100%;
    background-color: ${tema.cor.principal_clara};
    border: 1px solid ${tema.cor.cinza_claro};
    border-radius: 3px;
`;

export const ProcessoContainerTitulo = styled.button`
    width: 100%;
    text-align: left;
    background-color: ${props => props.selecionado ? tema.cor.principal_clara_selecionada : 'transparent'};
    border: none;
    border-bottom: 1px solid ${tema.cor.principal_claro_desaturado};
    font-weight: bold;
    font-size: 15px;
    padding: 7.5px 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    color: ${props => props.selecionado ? tema.cor.principal_escura : tema.cor.principal_escura_opaca};

    span {
        background-color: ${tema.cor.principal_escura};
        color: white;
        padding: 2px;
        border-radius: 500px;
    }
`;

export const TabelaContainer = styled.section`
    width: calc(100% - 250px);
    height: 100%;
    background-color: white;
    overflow-y: scroll;
    border: 1px solid ${tema.cor.cinza_claro};
    border-radius: 3px;
`;

export const Tabela = styled.table`
    width: 100%;
`;

export const TabelaCabeca = styled.thead`
    width: 100%;
    background-color: ${tema.cor.principal_clara};
`;

export const TabelaCabecaLinha = styled.tr`
    width: 100%;
    height: 30px;
    background-color: ${tema.cor.principal_clara};
`;

export const TabelaCabecaTitulo = styled.th`
    text-align: left;
    font-size: 15px;
    padding: 0 10px;
    border-left: 1px solid darkcyan;
    text-overflow: ellipsis;
    color: ${tema.cor.principal_escura};

    &:first-child {
        border-left: none;
    }
`;


export const BoxHeaderTable = styled.div`
width: 100%;
height: auto;
display: flex;
justify-content: space-between;
align-items: center;
`

export const TabelaCorpo = styled.tbody`
    width: 100%;
    cursor: default;
`;

export const TabelaCorpoLinha = styled.tr`
    width: 100%;
    height: 30px;
`;

export const TabelaCorpoInformacao = styled.td`
    text-align: ${props => props.valor ? 'right' : 'left'};
    font-size: 12.5px;
    font-weight: bold;
    padding: 0 10px;
    border: 1px solid ${tema.cor.cinza_claro};
    border-top: none;
    text-overflow: ellipsis;
    
    &:first-child {
        border-left: none;
    }

    button {
        border: none;
        background-color: transparent;
        cursor: pointer;
    }
`;

export const DetalheContainer = styled(TabelaContainer)`
    padding: 10px;
`;

export const DetalheTituloPrincipal = styled.h2`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
`;

export const DetalheHeader = styled.header`
    width: 100%;
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;
`;

export const DetalheHeaderContainer = styled.div``;

export const DetalheTitulo = styled.h2`
    font-size: 16px;
    font-weight: bold;
`;

export const DetalheTexto = styled.p`
    font-size: 15px;
`;

export const DetalheWrapper = styled.div`
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;



export const Wrapper = styled.div`
width: 100%;
height: auto;
`

