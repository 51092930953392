import { Component } from "react";
import { Titulo } from "../components/Sobre/style";
import { Bloco, TextoNormal, TituloParagrafo } from "../components/standard";


export default class PoliticaDePrivacidade extends Component {
    render() {
        return <>
            <Bloco style={{ padding: 50 }}>
                <Titulo style={{ fontSize: 40 }}>Politica de privacidade Faxinex</Titulo>

                <TextoNormal>Todas as suas informações pessoais recolhidas, serão usadas para o ajudar a tornar a sua visita no nosso site o mais produtiva e agradável possível. A garantia da confidencialidade dos dados pessoais dos utilizadores do nosso site é importante para a Faxinex. Todas as informações pessoais relativas a membros, assinantes, clientes ou visitantes que usem a Faxinex serão tratadas em concordância com a Lei da Proteção de Dados Pessoais em vigor. A informação pessoal recolhida pode incluir o seu nome, e-mail, número de telefone e/ou telemóvel e/ou outros. O uso da Faxinex pressupõe a aceitação deste acordo de privacidade. A equipa da Faxinex reserva-se ao direito de alterar este acordo sem aviso prévio. Deste modo, recomendamos que consulte a nossa política de privacidade com regularidade de forma a estar sempre atualizado.</TextoNormal>
                <TituloParagrafo>Ligações a sites de terceiros</TituloParagrafo>
                <TextoNormal>
                    A Faxinex possui ligações para outros sites, os quais, a nosso ver, podem conter informações / ferramentas úteis para os nossos visitantes. A nossa política de privacidade não é aplicada a sites de terceiros, pelo que, caso visite outro site a partir do nosso deverá ler a politica de privacidade do mesmo. Não nos responsabilizamos pela política de privacidade ou conteúdo presente nesses mesmos sites.
                </TextoNormal>
            </Bloco>
        </>
    }
}