import styled from "styled-components";
// import { tema_hotsite } from "../../../../assets/tema";
import { tema_hotsite } from "../../../assets/tema";

export const ContainerPrincipal = styled.div`
    width: 100vw;
    height: 100vh;
`;

export const ModalMenu = styled.div`
    width: 100%;
    height: 100vh;
    background-color: white;
    position: fixed;
    z-index: 100;
    display: ${props => props.visivel === true ? 'block' : 'none'};
    padding: 20px;
`;

export const ModalMenuLinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;


export const ModalMenuBotaoIcone = styled.button`
    background-color: transparent;
    border: none;
`;


export const HeaderPrincipal = styled.header`
    width: 100%;
    padding: 10px;
    background-color: ${tema_hotsite.cor};
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media(max-width: 800px){
    justify-content: space-between;
    padding: 0 20px;
    }
`;

export const BoxWeb = styled.nav`
width: 60%;
display: flex;
justify-content: end;
padding: 0 10px 0 0;
gap: 40px;
@media(max-width: 800px){
    display: none;}
`;

export const BoxMobile = styled.nav`
@media(max-width: 800px){
   display: flex;
   position: fixed;
   backdrop-filter: blur(3px);
   padding: 20px;
   gap:30px;
   top:0;
   bottom: 0;
   left:0;
   right:0;
   width: 100%;
   height: 100vh;
   z-index: 5;
   flex-direction: column;
   align-items:center;
   justify-content:start;
   background: rgb(0,151,167);
   background: linear-gradient(0deg, rgba(0,151,167,1) 0%, rgba(0,151,167,0.35898109243697474) 100%);
}
`
export const TextoNavLink = styled.a`
font-size: 25px;
color: white;
text-decoration: none;
cursor: pointer;

&:hover{
text-decoration: underline;
}
`;

export const MenuHamburguer = styled.button`
border:none;
background: transparent;
width: 80px;
@media(min-width: 801px){
    display: none;
}
}
`;

export const BotaoCloseHamburguer = styled.button`
border:none;
background: transparent;
width: 80px;
@media(min-width: 801px){
    display: none;
}
}
`;

export const HeaderBotaoMenu = styled.button`
    background-color: transparent;
    border: none;
`;

export const LogoPrincipal = styled.img`
    width: 100px;
`;

export const Banner = styled.img`
    width: 100vw;
    object-fit: contain;
`;

export const BannerTitulo = styled.h1`
    font-size: 70px;
    font-weight: bold;
    color: ${tema_hotsite.cor};
    text-align: center;
`;

export const BannerTexto = styled.p`
    font-size: 30px;
    color: black;
    text-align: center;
`;

export const Bloco = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    gap: 20px;
`;

export const BlocoTitulo = styled.h2`
    font-size: 25px;
    @media (min-width: 1023px) {
        text-align: center;
    }
`;

export const SobreContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: 1023px) {
        width: 1023px;
        margin: 0 auto;
        flex-direction: row;
    }
`;

export const SobreCard = styled.section`
    width: 100%;
    background-color: white;
    box-shadow: 1px 3px 10px rgba(0,0,0,0.5);
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (min-width: 1023px) {
        align-items: center;
        text-align: center;
    }
`;

export const SobreTitulo = styled.h2`
    font-size: 20px;
    text-decoration: underline;
    text-decoration-color: ${tema_hotsite.cor};
`;

export const SobreTexto = styled.p`
    font-size: 17px;
`;

export const FuncionalidadeContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: 1023px) {
        width: 1023px;
        margin: 0 auto;
        flex-direction: row;
    }
`;

export const FuncionalidadeCard = styled.section`
    width: 100%;
    background-color: white;
    box-shadow: 1px 3px 10px rgba(0,0,0,0.5);
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;

    @media (min-width: 1023px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
`;

export const FuncionalidadeTexto = styled.p`
    font-size: 17px;
`;

export const FuncionalidadeImagem = styled.img`
    width: 150px;
    object-fit: contain;
    margin: 0 auto;
`;

export const DepoimentoContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: 1023px) {
        width: 1023px;
        margin: 0 auto;
        flex-direction: row;
    }
`;

export const DepoimentoCard = styled.section`
    width: 100%;
    background-color: white;
    box-shadow: 1px 3px 10px rgba(0,0,0,0.5);
    padding: 10px;
    border-radius: 5px;
    display: flex;
    gap: 10px;

    @media (min-width: 1023px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
`;

export const DepoimentoImagem = styled.img`
    width: 150px;
    height: 150px;
    
    @media (min-width: 1023px) {
        border-radius: 500px;
    }
`;

export const DepoimentoInfoCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

export const DepoimentoTitulo = styled.h2`
    font-size: 20px;
`;

export const DepoimentoTexto = styled.p`
    font-size: 17px;
`;

export const FormularioContainer = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: 1023px) {
        width: 1023px;
        margin: 0 auto;
        flex-direction: row;
    }
`;

export const FormularioCard = styled.div`
    width: 350px;
    background-color: white;
    border-radius: 20px;
    margin: 0 auto;
    box-shadow: 1px 3px 10px rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;

    @media (min-width: 1023px) {
        width: 40%;
    }
`;

export const FormularioInputCard = styled.div`
    width: 100%;
`;

export const FormularioLabel = styled.label`
    font-size: 15px;
`;

export const FormularioInput = styled.input`
    width: 100%;
    padding: 8px;
    border: 1px solid black;
    font-size: 15px;
`;

export const FormularioSelect = styled.select`
    width: 100%;
    padding: 8px;
    border: 1px solid black;
    background-color: white;
`;

export const FormularioTitulo = styled.h1`
    font-size: 40px;
    color: black;
    text-align: center;

    span {
        text-decoration: underline;
        text-decoration-color: ${tema_hotsite.cor}
    }

    @media (min-width: 1023px) {
        font-size: 50px;
        width: 50%;
        text-align: left;
    }
`;

export const FormularioBotao = styled.button`
    width: 100%;
    padding: 15px;
    border-radius: 500px;
    background-color: ${tema_hotsite.cor};
    color: white;
    font-size: 15px;
    border: 1px solid ${tema_hotsite.cor};
    cursor: pointer;
`;

export const FooterPrincipal = styled.footer`
    width: 100%;
    padding: 30px 10px;
    background-color: ${tema_hotsite.cor};
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
`;

export const FooterImagem = styled.img`
    width: 200px;
    height: 100px;
    margin: 10px 0;
`;

export const FooterTexto = styled.a`
    font-size: 20px;
    color: white;
`;

export const WrapperHostSite = styled.div`
width: 100%;
`


export const TituloParagrafo = styled.h2`
text-align: left;
font-size: 35;
font-weight: bold;
margin: 30px 0px 3px 0px;
`
export const TextoNormal = styled.p`
font-size: 20px;
text-align: left;
color: #656464;
`
