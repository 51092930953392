import { Component } from "react";
import { Descricao, IconePessoas, InfoContainer, SobreContainer, Titulo } from './style';
import { IoPeople } from 'react-icons/io5';
import { BsGear, BsGearFill } from 'react-icons/bs';
import { FaBuilding } from 'react-icons/fa';

export default class extends Component {
    render() {
        return <SobreContainer id="sobre">
            <InfoContainer>
                <FaBuilding style={{ fontSize: 30 }} />
                <Titulo>Quem somos</Titulo>
                <Descricao>
                    O Faxinex é a solução que veio para conectar você que precisa de um serviço de limpeza a profissionais capacitados, treinados e de confiança para cuidar e fazer a limpeza do seu ambiente de trabalho.
                </Descricao>
            </InfoContainer>
            <InfoContainer>
                <BsGearFill style={{ fontSize: 30 }} />
                <Titulo>Como funciona</Titulo>
                <Descricao>
                    Apenas preenchendo o formulário e aguardando a sua faxina ir até você! Não é mais necessário contratar pessoas fixas para fazer a faxina, contrate um profissional Faxinex para fazer faxina do que você precisa!
                </Descricao>
            </InfoContainer>
            <InfoContainer>
                <IoPeople style={{ fontSize: 30 }} />
                <Titulo>Para quem</Titulo>
                <Descricao>
                    Feito para vocẽ que não tempo pra perder limpando, feito pra você que precisa de um profissional capacitado para fazer sua faxina com rapidez e qualidade! A limpeza do seu escritório ou espaço de trabalho é mais importante!
                </Descricao>
            </InfoContainer>
        </SobreContainer>
    }
}