import { Component } from "react";
import { AcoesContainer, AcoesContainerBotao, CabecalhoPrincipal, CabecalhoPrincipalBotao, CabecalhoPrincipalBotaoContainer, CabecalhoTopo, CabecalhoTopoBotao, CabecalhoTopoDireito, CabecalhoTopoEsquerdo, ContainerPrincipal, ConteudoPrincipal, ConteudoPrincipalContainer, DetalheContainer, DetalheHeader, DetalheHeaderContainer, DetalheTexto, DetalheTitulo, DetalheTituloPrincipal, LogoPrincipal, ProcessoContainer, ProcessoContainerTitulo, Tabela, TabelaCabeca, TabelaCabecaLinha, TabelaCabecaTitulo, TabelaContainer, TabelaCorpo, TabelaCorpoInformacao, TabelaCorpoLinha, DetalheWrapper, BoxHeaderTable } from "../../../components/standard";
import { GoEye } from 'react-icons/go';
import { GrUpdate } from 'react-icons/gr';
import { ImArrowLeft } from 'react-icons/im';
import { Navigate } from "react-router-dom";

import Constante from '../../../Util/Constante';
import Util from '../../../Util/Util';
import DepartamentoService from 'lirida-back-service/Servicos/Departamento/DepartamentoService';
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import DocumentoService from 'lirida-back-service/Servicos/Documento/DocumentoService';
import Pesquisa from 'lirida-back-service/Servicos/Pesquisa/Pesquisa';
import DocumentoItem from 'lirida-back-service/Servicos/Documento/DocumentoItem';
import TipoDocumentoProcesso from 'lirida-back-service/Servicos/TipoDocumentoProcesso/TipoDocumentoProcesso';
import logo from '../../../assets/images/logo.svg'
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

export default class DocumentoPG extends Component {

    constante = new Constante();
    util = new Util();
    departamentoService = new DepartamentoService(this.constante.token, this.constante.url_base);
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);

    state = {
        listaDepartamento: [],
        listaDocumentoItem: [],
        listaVwContadorDocumentoItem: [],
        documentoItem: new DocumentoItem(),
        detalheVisivel: false,
        listaRelacionamentoDocumentoItem: [],
        liberarAcessoTelaLogin: false,
        liberarAcessoTelaFinanceiro: false,
        tipoDocumentoProcesso: new TipoDocumentoProcesso(),
        pessoaUsuario: new Pessoa(),


        sorting: 'crescente',
    }


    componentDidMount() {
        this.iniciar();

    }


    async iniciar() {
        this.retornoLocalStorage = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        this.pessoaUsuario = this.retornoLocalStorage;
        if (!this?.pessoaUsuario?._seqPessoa) {
            window.history.back()
            return
        }

        this.retornoVwContadorDocumentoItem = await this.documentoService.listarContadorDocumentoItem(this.constante.seqUsuario, this.constante.seqTipoDocumento);

        console.log(this.retornoVwContadorDocumentoItem.objeto);

        this.retornoDepartamento = await this.departamentoService.listar(this.constante.seqUsuario);

        this.setState({
            listaDepartamento: this.retornoDepartamento.objeto, pessoaUsuario: this.pessoaUsuario
        })

        this.listar(this.retornoVwContadorDocumentoItem.objeto[0]);

    }


    async listar(pTipoDocumentoProcesso) {
        this.voltar()
        console.log(pTipoDocumentoProcesso)
        let tipoDocumentoProcesso = pTipoDocumentoProcesso


        this.retornoVwContadorDocumentoItem = await this.documentoService.listarContadorDocumentoItem(this.constante.seqUsuario, this.constante.seqTipoDocumento);

        console.log(this.retornoVwContadorDocumentoItem.objeto)

        this.setState({
            listaVwContadorDocumentoItem: this.retornoVwContadorDocumentoItem.objeto,
            listaDocumentoItem: [],
            tipoDocumentoProcesso: pTipoDocumentoProcesso
        });


        let pesquisa = [
            this.criarObjetoPesquisa(
                "documento.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            ),
            this.criarObjetoPesquisa(
                "documento_item.seq_tipo_documento_processo",
                tipoDocumentoProcesso._seqTipoDocumentoProcesso,
                "AND",
                "TEXTO"
            ),
            this.criarObjetoPesquisa(
                "documento_item_relacionamento.seq_tipo_relacionamento",
                this.constante.seqTipoRelacionamentoLocal,
                "AND",
                "TEXTO"
            ),
        ]

        this.retornoListaDocumentoItem = await this.documentoService.listarItemComFiltro(pesquisa);
        console.log('aaa')
        console.log(this.retornoListaDocumentoItem.objeto)
        this.setState({
            listaDocumentoItem: this.retornoListaDocumentoItem.objeto
        })
    }

    criarObjetoPesquisa(pCampo, pConteudo, pOperacao, pTipo) {
        let pesquisa = new Pesquisa();

        pesquisa.campo = pCampo;
        pesquisa.conteudo = pConteudo;
        pesquisa.operacao = pOperacao;
        pesquisa.tipo = pTipo;

        console.log(pesquisa)
        return pesquisa;
    }


    async selecionar(pDocumentoItem) {

        console.log('DETALHE');

        this.retornoListaRelacionamentoDocumentoItem = await this.documentoService.listarRelacionamentoItem(pDocumentoItem);

        console.log(this.retornoListaRelacionamentoDocumentoItem.objeto)
        console.log(pDocumentoItem)
        this.setState({
            detalheVisivel: true,
            documentoItem: pDocumentoItem,
            listaRelacionamentoDocumentoItem: this.retornoListaRelacionamentoDocumentoItem.objeto
        })
    }
    voltar() {
        this.setState({
            documentoItem: new DocumentoItem(),
        })
    }

    sair() {
        this.util.removerLocalStorage("PESSOA_USUARIO");
        this.setState({ liberarAcessoTelaLogin: true });
    }



    sortingLetra(col) {
        if (this.state.sorting === 'crescente') {
            this.sort = [...this.state.listaDocumentoItem].sort((a, b) => a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1);
            this.setState({ listaDocumentoItem: this.sort, sorting: 'decrescente' })
        }

        if (this.state.sorting === 'decrescente') {
            this.sort = [...this.state.listaDocumentoItem].sort((a, b) => a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1);
            this.setState({ listaDocumentoItem: this.sort, sorting: 'crescente' })
        }
    }

    sortingNumero(col) {
        if (this.state.sorting === 'crescente') {
            this.sort = [...this.state.listaDocumentoItem].sort((a, b) => a[col] - b[col]);
            this.setState({ listaDocumentoItem: this.sort, sorting: 'decrescente' })
        }

        if (this.state.sorting === 'decrescente') {
            this.sort = [...this.state.listaDocumentoItem].sort((a, b) => b[col] - a[col]);
            this.setState({ listaDocumentoItem: this.sort, sorting: 'crescente' })
        }
    }
    retornaTipoData(pStringData) {
        this.dataAtual = pStringData;
        this.dataAtual = this.dataAtual.split('T')[0].split('-').reverse();
        this.ano = this.dataAtual[0];
        this.mes = this.dataAtual[1];
        this.dia = this.dataAtual[2];
        this.novaData = `${this.ano}/${this.mes}/${this.dia}`
        this.novaData = new Date(this.novaData);
        return this.novaData
    }

    sortingData(col) {
        if (this.state.sorting === 'crescente') {
            this.sort = [...this.state.listaDocumentoItem].sort((a, b) => this.retornaTipoData(a[col]) - this.retornaTipoData(b[col]));
            this.setState({ listaDocumentoItem: this.sort, sorting: 'decrescente' })
        }

        if (this.state.sorting === 'decrescente') {
            this.sort = [...this.state.listaDocumentoItem].sort((a, b) => this.retornaTipoData(b[col]) - this.retornaTipoData(a[col]));
            this.setState({ listaDocumentoItem: this.sort, sorting: 'crescente' })
        }
    }

    render() {
        if (!this.state.pessoaUsuario._seqPessoa) {
            return <div></div>
        }
        return <ContainerPrincipal>

            {this.state.liberarAcessoTelaLogin && <Navigate to="/login" />}
            {this.state.liberarAcessoTelaFinanceiro && <Navigate to="/financeiro" />}

            <CabecalhoTopo>
                <CabecalhoTopoEsquerdo>Bem vindo, {this.state.pessoaUsuario.nome}</CabecalhoTopoEsquerdo>
                <CabecalhoTopoDireito>
                    {this.state.pessoaUsuario.emailPlataformaLirida}
                    <CabecalhoTopoBotao onClick={() => this.sair()}>sair</CabecalhoTopoBotao>
                </CabecalhoTopoDireito>
            </CabecalhoTopo>
            <CabecalhoPrincipal>
                <LogoPrincipal src={logo} />
                <CabecalhoPrincipalBotaoContainer>
                    <CabecalhoPrincipalBotao selecionado={true}>Ordens de serviço</CabecalhoPrincipalBotao>
                    <CabecalhoPrincipalBotao onClick={() => this.setState({ liberarAcessoTelaFinanceiro: true })}>Financeiro</CabecalhoPrincipalBotao>
                </CabecalhoPrincipalBotaoContainer>
            </CabecalhoPrincipal>

            <ConteudoPrincipal>
                <AcoesContainer>
                    {!this.state.documentoItem._seqDocumentoItem ?
                        <AcoesContainerBotao onClick={() => this.listar(this.state.tipoDocumentoProcesso)}>
                            <GrUpdate size={25} />
                            <div>Atualizar</div>
                        </AcoesContainerBotao> :
                        <AcoesContainerBotao onClick={() => this.voltar()}>
                            <ImArrowLeft size={25} />
                            <div>Voltar</div>
                        </AcoesContainerBotao>
                    }
                </AcoesContainer>
                <ConteudoPrincipalContainer>
                    <ProcessoContainer>
                        {this.state.listaVwContadorDocumentoItem.map(pTipoDocumentoProcesso =>
                            <ProcessoContainerTitulo
                                onClick={() => this.listar(pTipoDocumentoProcesso)}
                                selecionado={this.state.tipoDocumentoProcesso._seqTipoDocumentoProcesso === pTipoDocumentoProcesso._seqTipoDocumentoProcesso}
                            >
                                {pTipoDocumentoProcesso._tipoDocumentoProcessoNome}
                                < span > {pTipoDocumentoProcesso._contador}</span>
                            </ProcessoContainerTitulo>)}
                    </ProcessoContainer>
                    {!this.state.documentoItem._seqDocumentoItem ? <TabelaContainer>
                        <Tabela>
                            <TabelaCabeca>
                                <TabelaCabecaLinha>

                                    <TabelaCabecaTitulo onClick={() => this.sortingLetra("_tipoDocumentoProcessoNome")}>
                                        <BoxHeaderTable>
                                            Status
                                            {this.state.sorting === 'crescente' ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
                                        </ BoxHeaderTable>
                                    </TabelaCabecaTitulo>

                                    <TabelaCabecaTitulo onClick={() => this.sortingLetra("_itemNome")}>
                                        <BoxHeaderTable>
                                            Nome
                                            {this.state.sorting === 'crescente' ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
                                        </ BoxHeaderTable>
                                    </TabelaCabecaTitulo>

                                    <TabelaCabecaTitulo onClick={() => this.sortingNumero("quantidade")}>
                                        <BoxHeaderTable>
                                            Quantidade
                                            {this.state.sorting === 'crescente' ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
                                        </ BoxHeaderTable>
                                    </TabelaCabecaTitulo>


                                    <TabelaCabecaTitulo onClick={() => this.sortingNumero("precoUnitario")}>
                                        <BoxHeaderTable>
                                            Preco unitário
                                            {this.state.sorting === 'crescente' ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
                                        </ BoxHeaderTable>
                                    </TabelaCabecaTitulo>

                                    <TabelaCabecaTitulo onClick={() => this.sortingNumero("valorTotal")}>
                                        <BoxHeaderTable>
                                            Valor total
                                            {this.state.sorting === 'crescente' ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
                                        </ BoxHeaderTable>
                                    </TabelaCabecaTitulo>


                                    <TabelaCabecaTitulo onClick={() => this.sortingNumero("valorLiquido")}>
                                        <BoxHeaderTable>
                                            Valor líquido
                                            {this.state.sorting === 'crescente' ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
                                        </ BoxHeaderTable>
                                    </TabelaCabecaTitulo>


                                    <TabelaCabecaTitulo onClick={() => this.sortingData("dataCadastro")}>
                                        <BoxHeaderTable>
                                            Data cadastro
                                            {this.state.sorting === 'crescente' ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
                                        </ BoxHeaderTable>
                                    </TabelaCabecaTitulo>


                                    <TabelaCabecaTitulo onClick={() => this.sortingData("dataProgramada")}>
                                        <BoxHeaderTable>
                                            Data programada
                                            {this.state.sorting === 'crescente' ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
                                        </ BoxHeaderTable>
                                    </TabelaCabecaTitulo>


                                    <TabelaCabecaTitulo>*</TabelaCabecaTitulo>


                                </TabelaCabecaLinha>
                            </TabelaCabeca>
                            <TabelaCorpo>
                                {this.state.listaDocumentoItem.map(pDocumentoItem =>
                                    <TabelaCorpoLinha>
                                        <TabelaCorpoInformacao>{pDocumentoItem._tipoDocumentoProcessoNome}</TabelaCorpoInformacao>
                                        <TabelaCorpoInformacao>{pDocumentoItem._itemNomeVitrine}</TabelaCorpoInformacao>
                                        <TabelaCorpoInformacao>{pDocumentoItem.quantidade}</TabelaCorpoInformacao>
                                        <TabelaCorpoInformacao>{this.util.formatarValor(pDocumentoItem.precoUnitario)}</TabelaCorpoInformacao>
                                        <TabelaCorpoInformacao>{this.util.formatarValor(pDocumentoItem.valorTotal)}</TabelaCorpoInformacao>
                                        <TabelaCorpoInformacao>{this.util.formatarValor(pDocumentoItem.valorLiquido)}</TabelaCorpoInformacao>
                                        <TabelaCorpoInformacao>{this.util.formatarData(pDocumentoItem.dataCadastro)}</TabelaCorpoInformacao>
                                        <TabelaCorpoInformacao>{this.util.formatarData(pDocumentoItem.dataProgramada)}</TabelaCorpoInformacao>
                                        <TabelaCorpoInformacao>
                                            <button onClick={() => this.selecionar(pDocumentoItem)}>
                                                <GoEye size={20} />
                                            </button>
                                        </TabelaCorpoInformacao>
                                    </TabelaCorpoLinha>
                                )}
                            </TabelaCorpo>
                        </Tabela>
                    </TabelaContainer> : <DetalheContainer>
                        <DetalheTituloPrincipal>{this.state.documentoItem._itemNomeVitrine}</DetalheTituloPrincipal>

                        <DetalheHeader>



                            <DetalheHeaderContainer>
                                <DetalheTitulo>Status</DetalheTitulo>
                                <DetalheTexto>{this.state.documentoItem._tipoDocumentoProcessoNome}</DetalheTexto>
                            </DetalheHeaderContainer>


                            <DetalheHeaderContainer>
                                <DetalheTitulo>Solicitante</DetalheTitulo>
                                <DetalheTexto>{this.state.documentoItem._pessoaNome}</DetalheTexto>
                            </DetalheHeaderContainer>



                            <DetalheHeaderContainer>
                                <DetalheTitulo>Local para realização de serviço</DetalheTitulo>
                                <DetalheTexto>{this.state.documentoItem._pessoaRelacionadaNome}</DetalheTexto>
                            </DetalheHeaderContainer>


                            <DetalheHeaderContainer>
                                <DetalheTitulo>Complemento</DetalheTitulo>
                                <DetalheTexto>{this.state.documentoItem.tags2}</DetalheTexto>
                            </DetalheHeaderContainer>

                            <DetalheHeaderContainer>
                                <DetalheTitulo>Valor</DetalheTitulo>
                                <DetalheTexto>{this.util.formatarValor(this.state.documentoItem.precoUnitario)}</DetalheTexto>
                            </DetalheHeaderContainer>

                            <DetalheHeaderContainer>
                                <DetalheTitulo>Data cadastro</DetalheTitulo>
                                <DetalheTexto>{this.util.formatarData(this.state.documentoItem.dataCadastro)}</DetalheTexto>
                            </DetalheHeaderContainer>

                            <DetalheHeaderContainer>
                                <DetalheTitulo>Data programada</DetalheTitulo>
                                <DetalheTexto>{this.util.formatarData(this.state.documentoItem.dataProgramada)}</DetalheTexto>
                            </DetalheHeaderContainer>

                            <DetalheHeaderContainer>
                                <DetalheTitulo>Turno</DetalheTitulo>
                                <DetalheTexto>{this.state.documentoItem.tags0}</DetalheTexto>
                            </DetalheHeaderContainer>

                        </DetalheHeader>
                        <DetalheWrapper>
                            <DetalheTitulo>Relacionamento</DetalheTitulo>
                            <Tabela>
                                <TabelaCabeca>
                                    <TabelaCabecaLinha>
                                        <TabelaCabecaTitulo>Nome</TabelaCabecaTitulo>
                                        <TabelaCabecaTitulo>Tipo relacionamento</TabelaCabecaTitulo>
                                        <TabelaCabecaTitulo>Data cadastro</TabelaCabecaTitulo>
                                        {/* <TabelaCabecaTitulo>*</TabelaCabecaTitulo> */}
                                    </TabelaCabecaLinha>
                                </TabelaCabeca>
                                <TabelaCorpo>
                                    {this.state.listaRelacionamentoDocumentoItem.map(pRelacionamento =>
                                        <TabelaCorpoLinha>
                                            <TabelaCorpoInformacao>{pRelacionamento._pessoaRelacionadaNome}</TabelaCorpoInformacao>
                                            <TabelaCorpoInformacao>{pRelacionamento._tipoRelacionamentoNome}</TabelaCorpoInformacao>
                                            <TabelaCorpoInformacao>{this.util.formatarData(pRelacionamento.dataCadastro)}</TabelaCorpoInformacao>
                                            {/* <TabelaCorpoInformacao>
                                            <button onClick={() => this.selecionar(pDocumentoItem)}>
                                                <GoEye size={20} />
                                            </button>
                                        </TabelaCorpoInformacao> */}
                                        </TabelaCorpoLinha>
                                    )}
                                </TabelaCorpo>
                            </Tabela>
                        </DetalheWrapper>
                    </DetalheContainer>}
                </ConteudoPrincipalContainer>
            </ConteudoPrincipal>
        </ContainerPrincipal >
    }
}

