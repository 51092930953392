import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import { Component } from "react";
import { Navigate } from "react-router-dom";
import { Botao, Input, InputContainer, Label, LoginContainer, Logo, MainContainer } from "./style";
import Util from "../../Util/Util";
import Constante from "../../Util/Constante";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { Wrapper } from "../../components/standard";
import { BotaoRecuperarSenha } from './style';

import logo from "../../assets/images/logo.svg";

export default class Login extends Component {
    constante = new Constante()
    util = new Util()
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    state = {
        liberarAcessoTelaRecuperarSenha: false,
        liberarAcessoTelaLista: false,
        pessoaUsuario: new Pessoa(),
    }


    async validarAcesso() {
        this.listaPesquisa = [
            this.util.criarObjetoPesquisa(
                'pessoa.seq_usuario',
                this.constante.seqUsuario,
                '',
                'TEXTO'
            ),
            this.util.criarObjetoPesquisa(
                'pessoa.email_plataforma_lirida',
                this.state.pessoaUsuario.emailPlataformaLirida,
                'AND',
                'TEXTO'
            ),
            this.util.criarObjetoPesquisa(
                'pessoa.seq_pessoa_perfil',
                this.constante.seqPessoaPerfilOperador,
                'AND',
                'TEXTO'
            ),
        ]
        this.retorno = await this.pessoaService.listarComFiltro(this.listaPesquisa)
        this.pessoaUsuario = this?.retorno?.objeto[0];

        if (this.state.pessoaUsuario.emailPlataformaLirida !== this?.pessoaUsuario?.emailPlataformaLirida) {
            alert("Usuário inválido");
            return
        } else if (this.state.pessoaUsuario.senha !== this?.pessoaUsuario?.senha) {
            alert("Senha incorreta");
            return
        } else {
            await this.util.salvarLocalStorage("PESSOA_USUARIO", this.pessoaUsuario)
            this.setState({ liberarAcessoTelaLista: true })
        }

    }


    render() {
        return <MainContainer style={{ backgroundColor: '#f5f4ed' }}>

            {this.state.liberarAcessoTelaLista && <Navigate to='/documento' />}
            {this.state.liberarAcessoTelaRecuperarSenha && <Navigate to='/recuperarSenha' />}

            <LoginContainer onSubmit={(event) => this.validarAcesso(event.preventDefault())}>
                <Logo src={logo} alt="logo" />

                <InputContainer>
                    <Label>Usuário</Label>
                    <Input
                        onChange={pTexto => this.setState({
                            pessoaUsuario: {
                                ...this.state.pessoaUsuario, emailPlataformaLirida: pTexto.target.value
                            }
                        })}
                        type='email'
                        required />
                </InputContainer>

                <InputContainer>
                    <Label>Senha</Label>
                    <Input
                        onChange={pTexto => this.setState({
                            pessoaUsuario: {
                                ...this.state.pessoaUsuario, senha: pTexto.target.value
                            }
                        })}
                        type='password'
                        required
                    />
                </InputContainer>
                <Wrapper style={{ display: "flex", justifyContent: "flex-end" }}>
                    <BotaoRecuperarSenha
                        type='button'
                        onClick={() => this.setState({ liberarAcessoTelaRecuperarSenha: true })}
                    >Recuperar senha</BotaoRecuperarSenha>
                </Wrapper>
                <Botao type="submit">Entrar</Botao>
            </LoginContainer>

            versão 0.0.1 beta
        </MainContainer>
    }
}