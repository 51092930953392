import { Component } from 'react';
import { LogoBrancaRodape, RodapeContainer, RodapeNavContainer, RodapeNavLink, RodapeNavWrapper } from './style';
import logo from '../../../../assets/images/logo.svg';

export default class extends Component {
    render() {
        return <RodapeContainer>
            <RodapeNavContainer>
                <RodapeNavWrapper>
                    <RodapeNavLink
                        href="#sobre"
                        onClick={() => this.props.setState({ telaTermoDeUso: false, telaPoliticaPrivacidade: false })}>
                        Quem somos
                    </RodapeNavLink>
                    <RodapeNavLink
                        href="#depoimentos"
                        onClick={() => this.props.setState({ telaTermoDeUso: false, telaPoliticaPrivacidade: false })}>
                        Depoimentos
                    </RodapeNavLink>
                    <RodapeNavLink
                        href="#funcionalidade"
                        onClick={() => this.props.setState({ telaTermoDeUso: false, telaPoliticaPrivacidade: false })}>
                        Funcionalidade
                    </RodapeNavLink>
                </RodapeNavWrapper>
                <RodapeNavWrapper>
                    <RodapeNavLink
                        href='#header'
                        onClick={() => this.props.setState({ telaTermoDeUso: true, telaPoliticaPrivacidade: false })}
                    >
                        Termos de uso
                    </RodapeNavLink>
                    <RodapeNavLink
                        href='#header'
                        onClick={() => this.props.setState({ telaPoliticaPrivacidade: true, telaTermoDeUso: false })}
                    >
                        Politica de privacidade
                    </RodapeNavLink>
                </RodapeNavWrapper>
                <RodapeNavWrapper>
                    <RodapeNavLink href="https://www.instagram.com/appfaxinex/" target='_blank'>Instagram</RodapeNavLink>
                    <RodapeNavLink href="https://www.facebook.com/app.faxinex" target='_blank'>Facebook</RodapeNavLink>
                    {/* <RodapeNavLink>Youtube</RodapeNavLink>
                    <RodapeNavLink>Linkedin</RodapeNavLink> */}
                </RodapeNavWrapper>
            </RodapeNavContainer>
            <LogoBrancaRodape src={logo} />
        </RodapeContainer>
    }
}