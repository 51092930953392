import styled from "styled-components";
import { tema } from "../../assets/tema";

export const MainContainer = styled.div`
    width: 100%;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`;

export const RecuperarSenhaContainer = styled.form`
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

export const Logo = styled.img`
    width: 200px;
    object-fit: contain;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Label = styled.label`
    font-size: 17px;
    font-weight: bold;
`;

export const Input = styled.input`
    width: 300px;
    padding: 15px;
    border-radius: 3px;
    outline: none;
    border: 1px solid black;
`;

export const Botao = styled.button`
    width: 100%;
    padding: 15px;
    background-color: ${tema.cor.principal_fosco};
    color: white;
    font-weight: bold;
    font-size: 17px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    
`;

export const BotaoVoltarLogin = styled.button`
width: 50%;
text-align:right;
font-size:15px;
border: none;
background: transparent;
cursor: pointer;
color:${tema.cor.principal_fosco};

&:hover{
    text-decoration: underline;
}
`;