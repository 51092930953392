import styled from "styled-components";
import { tema_hotsite } from "../../../../assets/tema";

export const FuncionalidadeContainer = styled.section`
    width: 100%;
    padding: 10px;
`;

export const InfoContainer = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    @media screen and (max-width: 768px){
        flex-wrap: wrap;
        justify-content: center;
    }
`;

export const ImagemFlutuante = styled.img`
    width: 400px;
    object-fit: contain;
    
    @media screen and (max-width: 768px){
     width: 90%;
    }
`;

export const Descricao = styled.p`
    width: 50%;
    font-size: 25px;
    padding: 50px;
    
    @media screen and (max-width: 768px){
        width: 90%;
        text-align: center;
    }
`;