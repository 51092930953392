import styled from "styled-components";
import { tema_hotsite } from "../../../../assets/tema";

export const HeaderContainer = styled.header`
    width: 100%;
    height: 60px;
    background-color: ${tema_hotsite.cor};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
`;

export const HeaderWrapper = styled.div`
    width: 1200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
`;

export const HeaderLogo = styled.img`
    width: 100px;
    object-fit: contain;
    cursor: pointer;
`;

export const Navbar = styled.nav`
    display: flex;
    gap: 50px;
    align-items: center;
    margin-left:auto;
`;

export const NavbarLink = styled.a`
    font-size: 15px;
    color: white;
    text-decoration: none;

    @media screen and (max-width: 768px){
        display: none;
    }

    &:hover {
        text-decoration: underline;
    }
`;