import { Component } from 'react';
import { Descricao, FuncionalidadeContainer, ImagemFlutuante, InfoContainer, InfoContainerCentral } from './style';
import celular from '../../../../assets/images/celular.png';

export default class extends Component {
    render() {
        return <FuncionalidadeContainer id="funcionalidade">
            <InfoContainer style={{ textAlign: "right" }}>
                <ImagemFlutuante src={celular} />
                <Descricao>“Encontre um profissional de faxina mais perto de você com poucos cliques”</Descricao>
            </InfoContainer>
            <InfoContainer>
                <Descricao style={{ textAlign: "center", margin: '0 auto' }}>“Sua faxina vai até você!! Conforto e confiança à 100%!!”</Descricao>
            </InfoContainer>
            <InfoContainer>
                <Descricao>“Rapidez e praticidade na palma da sua mão! Chame agora o seu/sua “Faxineiro(a)”</Descricao>
                <ImagemFlutuante src={celular} style={{ transform: 'scaleX(-1)' }} />
            </InfoContainer>
        </FuncionalidadeContainer>
    }
}