export default class Constante {
    constructor() {
        this.token = 'f88f5e57-cf38-4be4-896b-0301c5be745e';
        // this.url_base = 'http://192.168.1.20:8080/lirida/api/';
        this.url_base = 'https://pallua.com.br/lirida_novo/api/';

        this.url_base_crosspag = "https://crosspag.com.br/cobranca?id=";
        // USUÁRIO
        this.seqUsuario = '9d80bef6-8aba-48f6-a8f6-e2d3c930d40f';


        //ITEM
        this.seqItem = '52e44327-8f65-4ac2-b838-ea4bb7a26890';

        //DOCUMENTO
        this.seqTipoDocumento = 'e86f7e03-4f85-4cc2-bd6c-52e17c095d85';
        this.seqTipoDocumentoProcessoNaoAgendado = '790aa1f3-80df-498c-9da5-c0e429e80509';
        this.seqTipoDocumentoProcessoAgendada = 'b3e5c194-75a7-482a-bc22-7750451a7ffd';
        this.seqTipoDocumentoProcessoFinalizada = '117bce63-1430-49b2-ab0b-39661af84435';

        //PESSOA
        this.seqPessoaPerfilCliente = '4e3a742d-c6fc-4549-af34-7e09f15a1ba2';
        this.seqPessoaPerfilPrestador = '2318618e-642c-4442-bda2-1957685eefda';
        this.seqPessoaPerfilCondominio = 'aaf53ff7-7e25-4bf8-8f45-a6cbf9ffbd1e';
        this.seqPessoaPerfilOperador = 'd4601fdd-e2b5-42f6-bdcc-aed18bee328d';

        //TABELA DE PREÇO
        this.seqTabelaPrecoPadrao = '7fef8800-927f-482e-a58c-7038de5e6eaa';

        //FORMA PAGAMENTO
        this.seqFormaPagamentoBoleto = 'f479331e-3bf2-4afe-bf15-a250e0ad9283';
        this.seqFormaPagamentoCartaoCredito = '3ce50728-4972-4383-8a1a-74ad4429019d';

        this.seqFormaPagamentoCartaoDebito = 'b1eccda8-86b7-4313-b670-92076f0e1994';
        this.seqFormaPagamentoPix = '6d43cc57-2514-4649-b9b5-65829f8585fa';

        //CONTA BANCARIA
        this.seqContaBacariaPrincipal = '479c5792-2d74-4dbd-aca0-453894155173';

        // RELACIONAMENTO
        this.seqTipoRelacionamentoPrestadorResponsavel = '8928c5a8-5b33-49d5-a550-f442f21a0c94';
        this.seqTipoRelacionamentoPrestadorCancelou = '8f6553c6-799f-4018-9039-2ce3f31d8069';
        this.seqTipoRelacionamentoSolicitante = 'c6165e1e-34a7-4a72-b68f-7481c40ac9b8';
        this.seqTipoRelacionamentoLocal = '11146514-db96-4779-957e-be5861c83eed';


        // ENDEREÇO
        this.seqTipoEnderecoEntrega = '7ffaf5b9-ad2e-4cf8-b30e-25a61fdcaf09';
        this.seqTipoEnderecoCobranca = 'b4062983-6166-4c91-8319-a25287b505b2';

        //TIPO DE CONTATO
        this.SeqTipoTelefone = "215e44df-7fb0-46ba-81ea-fc47984aa7ac";
        this.SeqTipoEmail = "787b95b2-d72b-46b4-8f94-9ec19f58b117";
        this.SeqTipoWhatsApp = "40c9d9fa-df1f-49f1-8f90-59dc2001f093";
        this.SeqTipoInstagram = "84c45973-ac60-45f3-ac10-e5c0b8822537";
        this.SeqTipoFacebook = "3a9b7211-371f-4534-9c2f-a3cd44c9e866";
        this.SeqTipoSite = "d09ef7d1-675b-4047-adeb-ebda83102e7a";


        //CATEGORIA ITEM 
        this.seqCategoriaItem = '46070434-0aa7-41f9-b4b2-e58db8fda5f9';

        //CONDICAO DE PAGEMENTO
        this.seqCondicaoPagamento = '952b6190-5a5e-495e-a885-0a404d1e789b';

        //EMAIL
        this.seqEmailEnvioSenha = "387c27f5-c0ec-4b9c-a420-0738a660905b";
        this.seqEmailEnvioConfirmacaoPedido = "f6fa7ba7-c3c9-4c73-9938-755c6b171e1e";
        this.seqEmalEnvioConfirmacaoPagamento = '80648a46-d9c8-4111-a1ea-f99ce63d56be';


        //FINANCEIRO
        this.seqPlanoContaFinanceiroVenda = "f4ec4edb-75d0-4597-af4b-872221beccde";

        /* ID */
        this.idListaContaBancaria = 'CB';
        this.idListaContaFinanceira = 'CF';
        this.idListaFormaPagamento = 'FP';
    }
}