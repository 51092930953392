import { Component } from "react";
import { BonusContainer, ImagemSlide, FraseImpacto, FraseImpactoTitulo, FraseImpactoDescricao } from "./style";
import computadores from '../../../../assets/images/computadores.png';

export default class extends Component {
    render() {
        return <BonusContainer>
            {/* <FraseImpactoTitulo>One Work</FraseImpactoTitulo> */}
            <FraseImpactoDescricao>Sua faxina a poucos cliques de você!! Peça agora!</FraseImpactoDescricao>
            {/* <ImagemSlide src={computadores} /> */}
        </BonusContainer>
    }
}