import styled from "styled-components";
import { tema_hotsite } from "../../../../assets/tema";

export const SobreContainer = styled.section`
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 50px 20px;
    /* flex-wrap: wrap; */
    background-color: ${tema_hotsite.cor};
    margin-top: -5px;

    @media screen and (max-width: 768px){
        flex-wrap: wrap;
        padding: 10px;
    }
`;

export const InfoContainer = styled.div`
    width: 300px;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;
    color: white;
`;

export const Titulo = styled.h3`
    font-size: 25px;
    text-decoration: underline;
    text-decoration-color: ${tema_hotsite.cor};
`;

export const Descricao = styled.p`
    font-size: 20px;
`;