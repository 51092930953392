import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './index.css';
import Login from './page/Login';
import Hotsite from './page/Hotsite';
import DocumentoPG from './page/Sistema/DocumentoPG/';
import RecuperarSenha from './page/RecuperarSenha';
import FinanceiroPG from './page/Sistema/FinanceiroPG';
import TermoDeUso from './page/Hotsite/TermoDeUso';
import PoliticaDePrivacidade from './page/Hotsite/PoliticaDePrivacidade';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Navigate to="/faxinex" />} />
      <Route path="/documento" element={<DocumentoPG />} />
      <Route path="/faxinex" element={<Hotsite />} />
      <Route path="/termoDeUso" element={<TermoDeUso />} />
      <Route path="/politicaDePrivacidade" element={<PoliticaDePrivacidade />} />
      <Route path="/login" element={<Login />} />
      <Route path="/financeiro" element={<FinanceiroPG />} />
      <Route path="/recuperarSenha" element={<RecuperarSenha />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
