const paleta = {
    azul: {
        principal_clara: '#d4dff4',
        principal_clara_selecionada: '#c0dcea',
        principal_claro_desaturado: '#c4cdd3',
        principal_escura_opaca: '#738b96',
        principal_fosco: '#447EAE',
        principal_escura: '#0f1f3d',
        principal_saturada_escura: '#0d31a8',
        principal_saturada: '#2602F5',
        principal: '#0D007A',
        cinza_claro: '#949391',
        cinza: '#797979',
        cinza_escuro: '#433F40',
    },
    verde_azulado: {
        principal_clara: '#c1e3e2',
        principal_clara_selecionada: '#b9ebe7',
        principal_claro_desaturado: '#b4d1cf',
        principal_escura_opaca: '#458585',
        principal_fosco: '#4cb2b5',
        principal_escura: '#036063',
        principal_saturada_escura: '#39bdbd',
        principal_saturada: '#2ed9d3',
        principal: '#0fb8b2',
        cinza_claro: '#949391',
        cinza: '#797979',
        cinza_escuro: '#433F40',
    },
    laranja: {
        principal_clara: '#e3d0c1',
        principal_clara_selecionada: '#ebd0b9',
        principal_claro_desaturado: '#d1c1b4',
        principal_escura_opaca: '#855f45',
        principal_fosco: '#b5734c',
        principal_escura: '#632d03',
        principal_saturada_escura: '#bd6939',
        principal_saturada: '#d9672e',
        principal: '#b85b0f',
        cinza_claro: '#949391',
        cinza: '#797979',
        cinza_escuro: '#433F40',
    },
    verde: {
        principal_clara: '#c7e3c1',
        principal_clara_selecionada: '#bbebb9',
        principal_claro_desaturado: '#b4d1b6',
        principal_escura_opaca: '#488545',
        principal_fosco: '#58b54c',
        principal_escura: '#096303',
        principal_saturada_escura: '#40bd39',
        principal_saturada: '#2ed937',
        principal: '#12b80f',
        cinza_claro: '#949391',
        cinza: '#797979',
        cinza_escuro: '#433F40',
    },
    vermelho: {
        principal_clara: '#e3c1c1',
        principal_clara_selecionada: '#ebb9b9',
        principal_claro_desaturado: '#d1b4b4',
        principal_escura_opaca: '#854545',
        principal_fosco: '#b54c4c',
        principal_escura: '#630303',
        principal_saturada_escura: '#bd3939',
        principal_saturada: '#d92e2e',
        principal: '#b80f0f',
        cinza_claro: '#949391',
        cinza: '#797979',
        cinza_escuro: '#433F40',
    },
}

export const tema = {
    cor: paleta.azul
}

export const tema_hotsite = {
    cor: '#0097A7'
}
