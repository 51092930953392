import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import { Component } from "react";
import { Navigate } from "react-router-dom";
import { Botao, Input, InputContainer, Label, RecuperarSenhaContainer, Logo, MainContainer, BotaoVoltarLogin } from "./style";
import Util from "../../Util/Util";
import Constante from "../../Util/Constante";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { Wrapper } from "../../components/standard";
export default class RecuperarSenha extends Component {
    constante = new Constante()
    util = new Util()
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    state = {
        pessoaUsuario: new Pessoa(),
        liberarAcessoTelaLogin: false
    }

    async recuperarSenha() {

        this.listaPesquisa = [
            this.util.criarObjetoPesquisa(
                'pessoa.seq_usuario',
                this.constante.seqUsuario,
                '',
                'TEXTO'
            ),
            this.util.criarObjetoPesquisa(
                'pessoa.email_plataforma_lirida',
                this.state.pessoaUsuario.emailPlataformaLirida,
                'AND',
                'TEXTO'
            ),
            this.util.criarObjetoPesquisa(
                'pessoa.seq_pessoa_perfil',
                this.constante.seqPessoaPerfilOperador,
                'AND',
                'TEXTO'
            ),
        ]
        this.retorno = await this.pessoaService.listarComFiltro(this.listaPesquisa)
        this.pessoaUsuario = this?.retorno?.objeto[0];
        if (this.state.pessoaUsuario.emailPlataformaLirida === this?.pessoaUsuario?.emailPlataformaLirida) {
            this.util.enviarEmailSenha(this.constante.seqEmailEnvioSenha, this.pessoaUsuario.emailPlataformaLirida, this.pessoaUsuario.senha)
            alert("Sua senha foi enviada por e-mail")
            this.setState({ liberarAcessoTelaLogin: true })
        } else {
            alert("Por favor, verifique se seu e-mail está correto")
            return
        }
    }

    render() {
        return <MainContainer>
            {this.state.liberarAcessoTelaLogin && <Navigate to="/login" />}

            <RecuperarSenhaContainer onSubmit={(event) => this.recuperarSenha(event.preventDefault())}>
                <Logo src='https://static.ricmais.com.br/uploads/2016/09/mcdonalds.jpg' alt="logo" />

                <InputContainer>
                    <Label>E-mail</Label>
                    <Input
                        onChange={pTexto => this.setState({
                            pessoaUsuario: {
                                ...this.state.pessoaUsuario, emailPlataformaLirida: pTexto.target.value
                            }
                        })}
                        type='email'
                        required />
                </InputContainer>
                <Wrapper style={{ display: "flex", justifyContent: "flex-end" }}>
                    <BotaoVoltarLogin
                        type='button'
                        onClick={() => this.setState({ liberarAcessoTelaLogin: true })}>Voltar para login</BotaoVoltarLogin>
                </Wrapper>
                <Botao type="submit">Entrar</Botao>
            </RecuperarSenhaContainer>

            versão 0.0.1 beta
        </MainContainer>
    }
}