import styled from "styled-components";
import { tema_hotsite } from "../../../../assets/tema";

export const BonusContainer = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 50px 20px;
`;

export const ImagemSlide = styled.img`
    width: 700px;
    object-fit: contain;

    @media screen and (max-width: 768px){
        width: 90%;
    }
`;

export const FraseImpactoTitulo = styled.h1`
    font-size: 50px;
    color: ${tema_hotsite.cor};
    font-weight: bold;
    font-style: normal;
`;

export const FraseImpactoDescricao = styled.h1`
    font-size: 30px;
    font-weight: normal;
    text-align: center;
    font-style: italic;
    text-decoration: underline;
    text-decoration-color: ${tema_hotsite.cor};
`;