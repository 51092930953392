import { Component } from 'react';
import { Card, Depoimento, DepoimentoContainer, Foto, InfoPessoaContainer, NomePessoa } from './style';
import rosto from '../../../../assets/images/rosto.png';

export default class extends Component {

    state = {
        indexDepoimento: 0,
    }

    render() {
        return <DepoimentoContainer id="depoimentos">
            <Card>
                <Foto src={rosto} />
                <InfoPessoaContainer>
                    <NomePessoa>Sérgio cardoso</NomePessoa>
                    <Depoimento>“Depois que descobri o Faxinex não quero mais outra coisa. Sempre que preciso tem um profissional na minha região para me atender”</Depoimento>
                </InfoPessoaContainer>
            </Card>
            <Card>
                <Foto src={rosto} />
                <InfoPessoaContainer>
                    <NomePessoa>Marcelo de Souza</NomePessoa>
                    <Depoimento>“Agora que trabalho pelo Faxinex recebo o justo! Antes ele era uma renda extra, agora virou minha renda principal, são muitos trabalhos!”</Depoimento>
                </InfoPessoaContainer>
            </Card>
            <Card>
                <Foto src={rosto} />
                <InfoPessoaContainer>
                    <NomePessoa>Luciana Ramos</NomePessoa>
                    <Depoimento>"A One Work me deu a segurança de poder contratar profissionais difíceis de achar como vallet, DJ e chef de cozinha. Todos capacitados e na palma da minha mão"</Depoimento>
                </InfoPessoaContainer>
            </Card>
        </DepoimentoContainer>
    }
}