import CondicaoPagamentoService from "lirida-back-service/Servicos/CondicaoPagamento/CondicaoPagamentoService";
import Contato from "lirida-back-service/Servicos/Contato/Contato";
import Documento from "lirida-back-service/Servicos/Documento/Documento";
import DocumentoItem from "lirida-back-service/Servicos/Documento/DocumentoItem";
import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";
import DocumentoItemRelacionamento from "lirida-back-service/Servicos/DocumentoItemRelacionamento/DocumentoItemRelacionamento";
import DocumentoRelacionamento from "lirida-back-service/Servicos/DocumentoRelacionamento/DocumentoRelacionamento";
import Financeiro from "lirida-back-service/Servicos/Financeiro/Financeiro";
import FinanceiroService from "lirida-back-service/Servicos/Financeiro/FinanceiroService";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import TabelaPrecoService from "lirida-back-service/Servicos/TabelaPreco/TabelaPrecoService";
import { Component } from "react";
import Constante from "../../Util/Constante";
import Util from "../../Util/Util";
import Bonus from "./components/Bonus";
import Depoimento from "./components/Depoimento";
import Funcionalidade from "./components/Funcionalidade";
import Header from "./components/Header";
// import Parceiro from "./components/Parceiro";
import Rodape from "./components/Rodape";
import Slide from "./components/Slide";
import Sobre from "./components/Sobre";
import { Bloco, FormularioBotao, FormularioCard, FormularioContainer, FormularioInput, FormularioInputCard, FormularioLabel, FormularioSelect } from "./components/standard";
import PoliticaDePrivacidade from "./PoliticaDePrivacidade";
import TermoDeUso from "./TermoDeUso";

export default class Hotsite extends Component {

    constante = new Constante();
    util = new Util(this.constante.token, this.constante.url_base);
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);
    tabelaPrecoService = new TabelaPrecoService(this.constante.token, this.constante.url_base);
    condicaoPagamentoService = new CondicaoPagamentoService(this.constante.token, this.constante.url_base);
    financeiroService = new FinanceiroService(this.constante.token, this.constante.url_base);

    state = {
        pessoa: new Pessoa(),
        contatoTelefone: new Contato(),
        documento: new Documento(),
        documentoItem: new DocumentoItem(),
        documentoRelacionamentoCondominio: new DocumentoRelacionamento(),
        documentoRelacionamentoSolicitante: new DocumentoRelacionamento(),
        documentoItemRelacionamentoCondominio: new DocumentoItemRelacionamento(),
        documentoItemRelacionamentoSolicitante: new DocumentoItemRelacionamento(),
        financeiro: new Financeiro(),
        seqFormaPagamento: '',
        listaPessoaCondominio: [],
        listaTabelaPreco: [],
        listaCondicaoPagamento: [],
        listaHora: ['7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00'],
        data: '',
        seqItem: '',
        seqPessoaCondominio: '',
        vModalMenu: false,
        vModalLogin: false,
        validadeCartao: '',
        menuMobileVisivel: false,


        //TELAS
        telaPoliticaPrivacidade: false,
        telaTermoDeUso: false,

    }



    componentDidMount() {
        this.iniciar()
    }

    async iniciar() {
        this.listarCondominio();
        this.listarTabelaPreco();
        this.listarFormaPagamento()
    }

    async listarFormaPagamento() {
        this.retorno = await this.condicaoPagamentoService.listarFormaPagamento(this.constante.seqCondicaoPagamento)
        this.retorno = this.retorno.objeto
        console.log(this.retorno)

        this.setState({
            listaCondicaoPagamento: this.retorno
        })
    }


    async listarTabelaPreco() {
        this.retorno = await this.tabelaPrecoService.listarTabelaPreco(this.constante.seqTabelaPrecoPadrao, '', 'S');
        this.retorno = this.retorno.objeto;
        console.log('LISTA TABELA PRECO');
        console.log(this.retorno);
        this.setState({
            listaTabelaPreco: this.retorno
        })
    }



    async listarCondominio() {
        this.lista = [
            this.util.criarObjetoPesquisa(
                "pessoa.seq_usuario",
                "9d80bef6-8aba-48f6-a8f6-e2d3c930d40f",
                "",
                "TEXTO"
            ),
            this.util.criarObjetoPesquisa(
                "pessoa.seq_pessoa_perfil",
                "aaf53ff7-7e25-4bf8-8f45-a6cbf9ffbd1e",
                "AND",
                "TEXTO"
            )
        ]

        this.retorno = await this.pessoaService.listarComFiltro(this.lista);

        console.log(this.retorno.objeto)
        this.setState({
            listaPessoaCondominio: this.retorno.objeto
        })
    }

    async buscarCondominioSelecionado() {
        this.lista = [
            this.util.criarObjetoPesquisa(
                "pessoa.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            ),
            this.util.criarObjetoPesquisa(
                "pessoa.seq_pessoa",
                this.state.seqPessoaCondominio,
                "AND",
                "TEXTO"
            )
        ]

        this.retorno = await this.pessoaService.listarComFiltro(this.lista);
        return this?.retorno?.objeto[0]?.apelido
    }

    async salvar() {
        //PESSOA CLIENTE

        this.pessoa = this.state.pessoa;
        this.pessoa.seqUsuario = this.constante.seqUsuario;
        this.pessoa.seqPessoaPerfil = this.constante.seqPessoaPerfilCliente;
        this.pessoa.seqTabelaPreco = this.constante.seqTabelaPrecoPadrao;
        if (this.pessoa.documento.length > 15) {
            this.pessoa.tipoDocumento = 'CNPJ'
        } else {
            this.pessoa.tipoDocumento = 'CPF'
        }

        this.retornoPessoa = await this.pessoaService.salvar(this.pessoa);
        console.log(this.retornoPessoa)
        this.pessoa = await this.retornoPessoa.objeto;
        console.log('PESSOA')
        console.log(this.pessoa);
        //

        // //DOCUMENTO
        this.documento = this.state.documento;
        this.documento.seqUsuario = this.constante.seqUsuario;
        this.documento.seqTipoDocumento = this.constante.seqTipoDocumento;
        this.documento.seqPessoa = this.pessoa._seqPessoa;
        this.documento.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoNaoAgendado;
        this.documento.dataProgramada = `${this.state.data.split('-').reverse().join('-')}T00:00:00`
        this.retornoDocumento = await this.documentoService.salvar(this.documento);
        this.documento = await this.retornoDocumento.objeto;
        console.log('DOCUMENTO')
        console.log(this.documento)
        // //


        //DOCUMENTO RELACIONAMENTO SOLICITANTE
        this.documentoRelacionamentoSolicitante = this.state.documentoRelacionamentoSolicitante;
        this.documentoRelacionamentoSolicitante.seqUsuario = this.constante.seqUsuario;
        this.documentoRelacionamentoSolicitante.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoSolicitante;
        this.documentoRelacionamentoSolicitante.seqPessoaRelacionada = this.pessoa._seqPessoa;
        this.documentoRelacionamentoSolicitante.seqDocumento = this.documento._seqDocumento;
        this.retornoRelacionamentoDocumentoSolicitante = await this.documentoService.salvarRelacionamento(this.documentoRelacionamentoSolicitante);
        console.log("DOCUMENTO RELACIONAMENTO SOLICITANTE SALVO")
        //

        //DOCUMENTO RELACIONAMENTO CONDOMINIO
        this.documentoRelacionamentoCondominio = this.state.documentoRelacionamentoCondominio;
        this.documentoRelacionamentoCondominio.seqUsuario = this.constante.seqUsuario;
        this.documentoRelacionamentoCondominio.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoLocal;
        this.documentoRelacionamentoCondominio.seqPessoaRelacionada = this.state.seqPessoaCondominio;
        this.documentoRelacionamentoCondominio.seqDocumento = this.documento._seqDocumento;
        this.retornoRelacionamentoDocumentoCondominio = await this.documentoService.salvarRelacionamento(this.documentoRelacionamentoCondominio)
        console.log('RELACIONAMENTO DOCUMENTO LOCAL SALVO');
        //  

        //DOCUMENTOITEM
        this.documentoItem = this.state.documentoItem;
        this.retornoNomeCondominio = await this.buscarCondominioSelecionado();
        this.documentoItem.seqUsuario = this.constante.seqUsuario;
        this.documentoItem.tags5 = this.retornoNomeCondominio;
        this.documentoItem.situacao = 'ATIVO';
        this.documentoItem.tags4 = this.state.seqPessoaCondominio;
        this.documentoItem.seqDocumento = this.documento._seqDocumento;
        this.documentoItem.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoNaoAgendado;
        this.documentoItem.seqItem = this.state.seqItem;
        this.documentoItem.quantidade = 1
        this.documentoItem.dataProgramada = `${this.state.data.split('-').reverse().join('-')}T00:00:00`
        this.listaTabelaPreco = this.state.listaTabelaPreco;
        this.itemNome = '';
        for (let i = 0; i < this.listaTabelaPreco.length; i++) {
            if (this.listaTabelaPreco[i].seqItem === this.state.seqItem) {
                this.documentoItem.precoUnitario = this.listaTabelaPreco[i].preco;
                this.documentoItem.precoTabela = this.listaTabelaPreco[i].preco;
                this.documentoItem.precoTabelaMaximo = this.listaTabelaPreco[i].precoMaximo;
                this.documentoItem.precoTabelaMinimo = this.listaTabelaPreco[i].precoMinimo;
                this.itemNome = `${this.listaTabelaPreco[i].itemNome} - R$${(this.util.formatarValor(this.listaTabelaPreco[i].precoMinimo))}`;
            }
        }
        this.retornoDocumentoItem = await this.documentoService.salvarItem(this.documentoItem);
        this.documentoItem = await this.retornoDocumentoItem.objeto;
        console.log('DOCUMENTO ITEM ===>');
        console.log(this.documentoItem);
        //


        //DOCUMENTO ITEM RELACIONAMENTO SOLICITANTE
        this.documentoItemRelacionamentoSolicitante = this.state.documentoItemRelacionamentoSolicitante;
        this.documentoItemRelacionamentoSolicitante.seqUsuario = this.constante.seqUsuario;
        this.documentoItemRelacionamentoSolicitante.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoSolicitante;
        this.documentoItemRelacionamentoSolicitante.seqPessoaRelacionada = this.pessoa._seqPessoa;
        this.documentoItemRelacionamentoSolicitante.seqDocumentoItem = this.documentoItem._seqDocumentoItem;
        this.retornoDocumentoItemRelacionamentoSoliciante = await this.documentoService.salvarRelacionamentoItem(this.documentoItemRelacionamentoSolicitante);
        this.retornoDocumentoItemRelacionamentoSoliciante = await this.retornoDocumentoItemRelacionamentoSoliciante.objeto
        console.log("DOCUMENTO ITEM RELACIONAMENTO SOLICITANTE")
        console.log(this.retornoDocumentoItemRelacionamentoSoliciante);
        //  

        //DOCUMENTO ITEM RELACIONAMENTO CONDOMINIO
        this.documentoItemRelacionamentoCondominio = this.state.documentoItemRelacionamentoCondominio;
        this.documentoItemRelacionamentoCondominio.seqUsuario = this.constante.seqUsuario;
        this.documentoItemRelacionamentoCondominio.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoLocal;
        this.documentoItemRelacionamentoCondominio.seqPessoaRelacionada = this.state.seqPessoaCondominio;
        this.documentoItemRelacionamentoCondominio.seqDocumentoItem = this.documentoItem._seqDocumentoItem;
        this.retoronoDocumentoItemRelacionamentoCondominio = await this.documentoService.salvarRelacionamentoItem(this.documentoItemRelacionamentoCondominio);
        this.retoronoDocumentoItemRelacionamentoCondominio = await this.retoronoDocumentoItemRelacionamentoCondominio.objeto;
        console.log("DOCUMENTO ITEM RELACIONAMENTO CONDOMINIO");
        console.log(this.retoronoDocumentoItemRelacionamentoCondominio);
        //


        //FINANCEIRO
        /* GERANDO FINANCEIRO */
        this.financeiro = new Financeiro();
        this.financeiro.seqTipoDocumento = this.constante.seqTipoDocumento;
        this.financeiro.seqUsuario = this.documentoItem.seqUsuario;
        this.financeiro.seqPessoa = this.pessoa._seqPessoa;
        this.financeiro.seqContaBancaria = this.constante.seqContaBacariaPrincipal;
        this.financeiro.seqContaFinanceira = this.constante.seqPlanoContaFinanceiroVenda;
        this.financeiro.valorProgramado = this.documentoItem.precoTabelaMaximo;
        this.financeiro.dataProgramado = new Date();
        this.financeiro.documento = this.pessoa.documento;
        this.financeiro.tipoDocumento = this.pessoa.tipoDocumento;
        this.financeiro.nome = this.pessoa.nome;
        this.financeiro.qtdeBaixa = 0;
        this.financeiro.seqFormaPagamento = this.constante.seqFormaPagamentoPix;
        this.financeiro.seqDocumentoItem = this.documentoItem._seqDocumentoItem;
        this.financeiro.seqDocumento = this.documento._seqDocumento;
        this.financeiro.observacao = 'Essa cobrança é referente a plataforma: faxinex.com.br\n\n'
        this.financeiro.observacao = this.financeiro.observacao + 'Condomínio: ' + this.retornoNomeCondominio + '\n Sala: ' + this.documentoItem.tags2 + '\n';
        this.financeiro.observacao = this.financeiro.observacao + 'Quantidade: ' + this.itemNome + '\n';
        this.financeiro.observacao = this.financeiro.observacao + 'Agendado para: ' + this.util.formatarData(this.documentoItem.dataProgramada) + '\n Horário: ' + this.documentoItem.tags0 + 'h\n\n' + 'A nota fiscal será emitida após a execução do serviço.';
        console.log('OBSERVACAO')
        console.log(this.financeiro.observacao)
        this.retornoFinanceiro = await this.financeiroService.salvar(this.financeiro);
        this.financeiro = this.retornoFinanceiro.objeto
        console.log('FINANCEIROOOOO VVVVVVV', this.retornoFinanceiro);
        window.open(this.constante.url_base_crosspag + this.financeiro._seqMovimentoFinanceiro, '_blank')

        this.util.enviarEmailConfirmarPedido(this.pessoa.emailPlataformaLirida, this.pessoa.nome, this.financeiro._seqMovimentoFinanceiro);

        this.setState({
            pessoa: new Pessoa(),
            contatoTelefone: new Contato(),
            documento: new Documento(),
            documentoItem: new DocumentoItem(),
            documentoRelacionamentoCondominio: new DocumentoRelacionamento(),
            documentoRelacionamentoSolicitante: new DocumentoRelacionamento(),
            documentoItemRelacionamentoCondominio: new DocumentoItemRelacionamento(),
            documentoItemRelacionamentoSolicitante: new DocumentoItemRelacionamento(),
            financeiro: new Financeiro(),
            seqFormaPagamento: '',
            listaPessoaCondominio: [],
            listaTabelaPreco: [],
            listaCondicaoPagamento: [],
            data: '',
            seqItem: '',
            seqPessoaCondominio: '',
            vModalMenu: false,
            vModalLogin: false,
            validadeCartao: ''
        })

    }

    alternarMenu() {
        this.setState({ vModalMenu: !this.state.vModalMenu })
    }

    emailConsulta() {
        window.prompt("Informe um e-mail que lhe enviaremos um link para sua consulta.");

    }

    render() {
        return <>

            <Header
                setState={(e) => this.setState(e)}
                emailConsulta={() => this.emailConsulta()}
            />

            {this.state.telaTermoDeUso &&
                <TermoDeUso />
            }

            {this.state.telaPoliticaPrivacidade &&
                <PoliticaDePrivacidade />
            }

            {!this.state.telaTermoDeUso && !this.state.telaPoliticaPrivacidade && <>
                <Slide />
                <Sobre />
                <Funcionalidade />
                <Depoimento />
                <Bonus />


                <Bloco>
                    <FormularioContainer onSubmit={(event) => this.salvar(event.preventDefault())}>
                        <FormularioCard >

                            <FormularioInputCard >
                                <FormularioLabel>Nome</FormularioLabel>
                                <FormularioInput
                                    onChange={(pTexto) => this.setState({
                                        pessoa: { ...this.state.pessoa, nome: pTexto.target.value }
                                    })}
                                    type='text'
                                    required={true}
                                    value={this.state.pessoa.nome}
                                    placeholder='Seu nome completo'
                                />
                            </FormularioInputCard>

                            <FormularioInputCard>
                                <FormularioLabel>CPF/CNPJ</FormularioLabel>
                                <FormularioInput onChange={(pTexto) => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa, documento: this.util.formatarCPFCNPJ(pTexto.target.value)
                                    }
                                })}
                                    type='tel'
                                    required={true}
                                    value={this.state.pessoa.documento}
                                    placeholder='000.000.00-00'
                                />
                            </FormularioInputCard>

                            <FormularioInputCard>
                                <FormularioLabel>Telefone</FormularioLabel>
                                <FormularioInput
                                    onChange={(pTexto) => this.setState({
                                        contatoTelefone: {
                                            ...this.state.contatoTelefone,
                                            contato: this.util.formatarTelefone(pTexto.target.value)
                                        }
                                    })}
                                    placeholder='(00) 00000-0000'
                                    required={true}
                                    type={'tel'}
                                    value={this.state.contatoTelefone.contato} />
                            </FormularioInputCard>


                            <FormularioInputCard>
                                <FormularioLabel>E-mail</FormularioLabel>
                                <FormularioInput
                                    onChange={(pTexto) => this.setState({
                                        pessoa: {
                                            ...this.state.pessoa, emailPlataformaLirida: pTexto.target.value
                                        }
                                    })}
                                    placeholder='email@email.com'
                                    required={true}
                                    type='email'
                                    value={this.state.pessoa.emailPlataformaLirida} />
                            </FormularioInputCard>

                            <FormularioInputCard>
                                <FormularioLabel>Local do serviço</FormularioLabel>
                                <FormularioSelect
                                    onChange={(pItem) => { this.setState({ seqPessoaCondominio: pItem.target.value }) }}
                                >
                                    <option value="">Selecione</option>
                                    {this.state.listaPessoaCondominio.map(item => <option value={item._seqPessoa}>{item.nome}</option>)}
                                </FormularioSelect>
                            </FormularioInputCard>


                            <FormularioInputCard>
                                <FormularioLabel>Selecione a quantidade</FormularioLabel>
                                <FormularioSelect
                                    onChange={(pItem) => this.setState({ seqItem: pItem.target.value })}
                                >
                                    <option value="">Selecione</option>
                                    {this.state.listaTabelaPreco.map(item => <option value={item.seqItem}>{`${item.itemNome} - R$${this.util.formatarValor(item.precoMinimo)}`}</option>)}
                                </FormularioSelect>
                            </FormularioInputCard>



                            <FormularioInputCard >
                                <FormularioLabel>Bloco / Sala</FormularioLabel>
                                <FormularioInput
                                    onChange={(pTexto) => this.setState({
                                        documentoItem: {
                                            ...this.state.documentoItem, tags2: pTexto.target.value
                                        }
                                    })}
                                    placeholder='Exemplo: Bloco 01 / Sala 404'
                                    required={true}
                                    type='text'
                                    value={this.state.documentoItem.tags2}
                                />
                            </FormularioInputCard>

                            <FormularioInputCard>
                                <FormularioLabel>Data agendamento</FormularioLabel>
                                <FormularioInput
                                    onChange={(pTexto) => this.setState({ data: pTexto.target.value })}
                                    required={true}
                                    type='date'
                                    min={new Date().toISOString().split("T")[0]}
                                    value={this.state.documentoItem.dataProgramada}
                                />
                            </FormularioInputCard>

                            <FormularioInputCard>
                                <FormularioLabel>Hora de serviço</FormularioLabel>
                                <FormularioSelect
                                    onChange={(pItem) => {
                                        this.setState({
                                            documentoItem: {
                                                ...this.state.documentoItem, tags0: pItem.target.value
                                            }
                                        })
                                    }}
                                >
                                    <option value="">Selecione</option>
                                    {this.state.listaHora.map(item => <option value={item}>{item}</option>)}

                                </FormularioSelect>
                            </FormularioInputCard>

                            <FormularioBotao type="submit">Continue para pagamento</FormularioBotao>
                        </FormularioCard>
                    </FormularioContainer>
                </Bloco>
            </>
            }
            {/* <Parceiro /> */}
            <Rodape
                setState={(e) => this.setState(e)}
            />
        </>
    }
}