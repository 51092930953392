import styled from "styled-components";
import { tema_hotsite } from "../../../../assets/tema";

export const DepoimentoContainer = styled.section`
    width: 100%;
    background-color: ${tema_hotsite.cor};
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 20px;

    @media screen and (max-width: 768px){
        flex-direction: column;
    }
`;

export const Card = styled.div`
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-direction: column;
    text-align: center;
    background-color: white;
    border-radius: 5px;
    padding: 20px;

    @media screen and (max-width: 768px){
        width: 100%;
    }
`;

export const Foto = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 500px;
`;

export const InfoPessoaContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const NomePessoa = styled.h3`
    font-size: 20px;
    font-style: italic;
`;

export const Depoimento = styled.p`
    font-size: 17px;
`;

export const Botao = styled.button`
    width: 50px;
    height: 50px;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;